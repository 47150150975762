import React from 'react';
import Dropdown from './Dropdown';
import './InputDate.css';

const range = (start: number, end: number) =>
  Array(end - start + 1).fill(0).map((_, idx) => start + idx);

const getLastDayofMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0);

interface IProps {
  date: Date,
  callback: any
}

export default function InputDate(props: IProps) {
  let date = props.date;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const years = range(date.getFullYear() - 15, date.getFullYear() + 15);
  const days = range(1, getLastDayofMonth(date).getDate());

  const setDay = (day: any) => {
    date.setDate(parseInt(day));
    props.callback(date);
  }

  const setMonth = (month: any) => {
    const mth = months.indexOf(month);
    // const end = getLastDayofMonth(new Date(date.getFullYear(), mth, 1)).getDate()
    // if (date.getDate() > end) date.setDate(end);
    date.setMonth(mth);
    props.callback(date);
  }

  const setYear = (year: any) => {
    date.setFullYear(parseInt(year));
    props.callback(date);
  }

  return (
    <div className='InputDate'>
      <span className='InputDateDay'><Dropdown value={date.getDate()} values={days} callback={setDay} /></span>
      <span>-</span>
      <span className='InputDateMonth'><Dropdown value={months[date.getMonth()]} values={months} callback={setMonth} /></span>
      <span>-</span>
      <span className='InputDateYear'><Dropdown value={date.getFullYear()} values={years} callback={setYear} /></span>
    </div>
  );
}