import React from 'react';
import './Terms.css';

export default () =>
    <div id="Terms">
        <h1>Terms and Conditions</h1>
        <p>The website <b>myquant.com.au</b> (this website) is owned by <b>myQuant Pty. Ltd. ACN 610 992 437</b> (myQuant).</p>
        <p>Your access to this website is governed by these Terms and Conditions and the <a href="/privacy">Privacy Statement</a> of myQuant, and any additional terms and conditions or statements contained on this website. By accessing, viewing or otherwise using this website, you agree to be subject to these Terms and Conditions.</p>
        <p>myQuant may, at its sole discretion, modify these Terms and Conditions without notice. Any new Terms and Conditions will be published on this website. Your subsequent or ongoing use of the website will constitute an acceptance of any modified terms of use.</p>
        <p className="red">Material on this website is provided for general information purposes only and your use of the material is <b>at your own risk</b>. Material on this website does not constitute advice of any kind and must not be relied upon as such. myQuant does not guarantee the security of this website or give any warranty of reliability or accuracy, and accepts no liability including liability in negligence for errors in, or omissions from, the information on this website. myQuant does not accept any liability for any loss or damage, however caused, as a result of any person relying on any information on this website or being unable to access this website. This disclaimer is subject to any applicable contrary provisions of the Australian Consumer Law.</p>
        <p>myQuant is the owner of the myQuant trade mark and all other intellectual property rights subsisting in this website. <b>The information contained on this website is protected by copyright.</b> You may use this information for your own personal reference only. Apart from a temporary copy stored in your computer's cache and a single hard copy for your personal reference, the information must not otherwise be reproduced, published, altered, distributed or transmitted in any form or by any means in whole or in part.</p>
    </div>