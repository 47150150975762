import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import QuotePage from './components/pages/QuotesPage';
import UserGuide from './components/pages/UserGuide';
import Terms from './components/pages/Terms';
import Privacy from './components/pages/Privacy';
import Downloads from './components/pages/Downloads';
import License from './components/pages/License';
import Currencies from './components/blocks/Currencies';
import Reset from './components/buttons/Reset';
import Sample from './components/buttons/Sample';
import SyntheticPage from './components/pages/SyntheticPage';
import Load from './components/buttons/Load';
import InterestCurves from './components/charts/InterestCurves';
import InflationCurve from './components/charts/InflationCurve';
import ResetPage from './components/pages/ResetPage';
import Logs from './components/pages/Logs';
import TradesPage from './components/pages/TradesPage';
import TopBar from './components/bars/TopBar';
import NavigationBar from './components/bars/NavigationBar';
import ButtonBar from './components/bars/ButtonBar';
import MainFrame from './components/frames/MainFrame';
import ValuationDate from './components/blocks/ValuationDate';
import Cogwheel from './components/pages/Cogwheel';
import CreateTrade from './components/popups/CreateTrade';
import Create from './components/buttons/Create';
import Disclaimer from './components/popups/Disclaimer';
import store from './states/Store';
import './App.css';
import Clear from './components/buttons/Clear';

export default function App(props: any) {
  const [currency, setCurrency] = useState(store.MarketData.Currency.getValue().toLowerCase());

  useEffect(() => {
    store.MarketData.Currency.subscribe(currency => setCurrency(currency.toLowerCase()));
  });  

  const n = 5;
  const routes = (currency: string) => [
    {
      path: () => `/${currency}/quotes`,
      exact: true,
      label: "Quotes",
      buttons: [<Load />, <Reset />, <Sample />, <Currencies />, <ValuationDate />],
      main: () => <QuotePage />
    },
    {
      path: () => `/${currency}/instruments`,
      label: "Instruments",
      buttons: [<Currencies />],
      main: () => <SyntheticPage />
    },
    {
      path: () => `/${currency}/interestcurves`,
      label: "InterestCurves",
      buttons: [<Currencies />],
      main: () => <InterestCurves />
    },
    {
      path: () => `/${currency}/inflationcurve`,
      label: "InflationCurve",
      buttons: [<Currencies />],
      main: () => <InflationCurve />
    },
    {
      path: () => `/${currency}/trades`,
      label: "Trades",
      buttons: [<Create />, <Currencies />, <ValuationDate />],
      main: () => <TradesPage />
    },
    {
      path: () => "/userguide",
      label: "User Guide",
      buttons: [],
      main: () => <UserGuide />
    },
    {
      path: () => "/terms",
      label: "Terms and Conditions",
      buttons: [],
      main: () => <Terms />
    },
    {
      path: () => "/privacy",
      label: "Privacy Statement",
      buttons: [],
      main: () => <Privacy />
    },
    // {
    //   path: () => "/contact",
    //   label: "Contact",
    //   buttons: [],
    //   main: () => <Terms />
    // },
    {
      path: () => "/downloads",
      label: "Downloads",
      buttons: [],
      main: () => <Downloads />
    },
    {
      path: () => "/license",
      label: "License",
      buttons: [],
      main: () => <License />
    },
    // {
    //   path: () => "/login",
    //   label: "Sign-in",
    //   buttons: [],
    //   main: () => <Terms />
    // },
    {
      path: () => "/logs",
      label: "Logs",
      buttons: [<Clear />],
      main: () => <Logs />
    },
    {
      path: () => "/reset",
      label: "",
      buttons: [],
      main: () => <ResetPage />
    }
  ];

  return (
    <div>
      <Disclaimer />
      <CreateTrade />
      <Cogwheel />
      <Router>
        <Route exact path="/"><Redirect to={`${currency}/quotes`} /></Route>
        <TopBar routes={routes(currency).slice(0, n)} />
        <MainFrame routes={routes(":ccy")} />
        <ButtonBar routes={routes(":ccy")} />
        <NavigationBar routes={routes(currency).slice(n, routes(currency).length - 1)} />
      </Router>
    </div>);
}
