import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QuoteTable from '../tables/QuoteTable';
import Quote from '../../classes/Quote';
import store from '../../states/Store';
import BasisTable from '../tables/BasisTable';
import '../../classes/Extensions';

const types = [
  ['DEPO'],
  ['FUT'],
  ['OIS'],
  ['IRS'],
  ['DBS'],
  ['FX', 'XCS'],
  ['CPI', 'ZCIS']
];

const legends = [
  'Deposits',
  'Futures',
  'OIS Swaps',
  'Swaps',
  'Basis Spreads',
  'Forex',
  'Inflation'
];

const headers = [
  ['Tenor', 'Quote'],                     // DEPO
  ['Code', 'Quote'],                      // FUT
  ['Term', 'Quote'],                      // OIS
  ['Tenor', 'Term', 'Quote'],             // IRS
  ['Term', '0s3s', '1s3s', '3s6s'],       // DBS
  ['Type', 'Tenor', 'Quote'],             // FX
  ['Term', 'Quote']                       // CPI
];

const properties = [
  ['Maturity'],                  // DEPO
  ['Maturity'],                  // FUT
  ['Maturity'],                  // OIS
  ['Tenor', 'Maturity'],         // IRS
  ['Maturity'],                  // DBS: not used
  ['Type', 'Maturity'],          // FX
  ['Maturity']                   // CPI
];

export default function QuotePage(props: any) {
  const [quotes, setQuotes] = useState(store.MarketData.Current.Quotes.getValue());

  useEffect(() => {
    store.MarketData.Current.Quotes.subscribe((quotes) => setQuotes(quotes));
  })

  return (
    <div>
      {types.map((types: string[], index: number) => {
        const lquotes = quotes.filter((quote: Quote) => types.indexOf(quote.Type) !== -1)
        if (!lquotes || lquotes.length === 0) return '';
        return types[0] === 'DBS'
          ? (<BasisTable key='DBS' Quotes={lquotes}></BasisTable>)
          : (<QuoteTable
            key={types[0]}
            legend={legends[index]}
            headers={headers[index]}
            properties={properties[index]}
            Quotes={lquotes} >
          </QuoteTable>);
      })}
    </div>
  );
}

QuotePage.propTypes = {
  Quotes: PropTypes.array,
};