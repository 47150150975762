import React, { useState, useEffect } from 'react';
import TradeRow from './TradeRow';
import store from '../../states/Store';
import Trade from '../../classes/Trade';
import Factory from '../../classes/Factory';
import './TradesPage.css';

export default function TradePage() {
  const [portfolio, setPortfolio] = useState(store.MarketData.Current.Portfolio.getValue());
  const [signature, setSignature] = useState('');

  useEffect(() => {
    store.MarketData.Current.Portfolio.subscribe(portfolio => {
      setPortfolio(portfolio);
      setSignature(JSON.stringify(portfolio));
    });
  }, []);

  const updateTrade = (trade: Trade, index: number) => {
    portfolio[index] = trade;
    setPortfolio(portfolio);
    setSignature(JSON.stringify(portfolio));
  }

  const deleteTrade = (index: number) => {
    delete portfolio[index];
    setPortfolio(portfolio);
    setSignature(JSON.stringify(portfolio));
  }

  const currency = store.MarketData.Currency.getValue();
  return (
    <div className="TradesPage">
      <ul>
        {portfolio.map((trade: Trade, index: number) => {
          return Factory.IsNullOrEmpty(trade?.Description) ? '' : <TradeRow
            key={`Portfolio.${currency}.${index}`}
            index={index}
            trade={trade}
            updateTrade={updateTrade}
            deleteTrade={deleteTrade} />
        }
        )}
      </ul>
    </div>);
}