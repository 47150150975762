import React from 'react';
import { Route } from 'react-router-dom';
import IRouteable from '../../interfaces/IRouteable';
import './ButtonBar.css';

const Buttons = (props: any) =>
    <table>
        <tbody>
            <tr>
                {props.buttons.map((button: any, i: number) => <td key={`${props.label}:${i}`}>{button}</td>)}
                <td key={`${props.label}:empty`}></td>
            </tr>
        </tbody>
    </table>

const ButtonBar = (props: any) =>
    <div className="ButtonBar">
        {props.routes.map((route: IRouteable) => (
            <Route
                key={route.path()}
                path={route.path()}
                exact={route.exact}
                component={() => <Buttons buttons={route.buttons as any[]} label={route.path()} />}
            />
        ))}
    </div>

export default ButtonBar