import React, { useState, useEffect } from 'react';
import store from '../../states/Store';
import "./Cogwheel.css"

export default function Cogwheel() {
    const [busy, setBusy] = useState(false);
        
    useEffect(()=>{
        store.UI.Busy.subscribe(() => update());
    });

    const delay = (ms: number) => new Promise( resolve => setTimeout(resolve, ms));

    const update = async () => {
        await delay(300);
        setBusy(store.UI.Busy.getValue());
    }

    return !busy ? null : (<div className="Cogwheel"><img src="/img/waiting.gif" alt="cogwheel" /></div>);
}