import React from 'react';
import "./ResetPage.css";

export default () =>
    <div>
        <form>
            <table>
                <tr>
                    <td>Username</td>
                    <td><input name="email" /></td>
                </tr>
                <tr>
                    <td>New Password</td>
                    <td><input type="password" name="password" /></td>
                </tr>
                <tr>
                    <td>Confirm Password</td>
                    <td><input type="password" name="password" /></td>
                </tr>
                {/* <tr>
                    <td><input name="error" /></td>
                </tr> */}
                <tr>
                    <td><span>Submit</span></td>
                </tr>
            </table>
        </form>
    </div>
