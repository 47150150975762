import store from "../states/Store";
import Log, { LogType } from "./Log";

export default class Service {
  public static async post(service: string, method: string, json: string = "", cached = false): Promise<any> {
    const cache = store.Service.Dictionary.getValue() as any;
    const key = this.getKey(service, method, json);
    if (cached && cache[key] !== undefined) return cache[key];

    const start = new Date().getTime();
    const response = await this.fetch(service, method, json);
    const data = await response.json();
    const end = new Date().getTime();

    if (response.ok)
      store.Logs.push(new Log(LogType.Info, service, method, end - start));
    else {
      store.Logs.push(new Log(LogType.Error, service, method, 0, data.Message, data.Code));
      return null;
    }

    if (cached){
      cache[key] = data;
      store.Service.Dictionary.next(cache);
    }

    return data;
  }

  private static handleError = (err: any) => {
    return new Response(JSON.stringify({
      code: 400,
      message: 'Stupid network Error'
    }));
  };

  private static fetch = async (service: string, method: string, json: string = "") =>
    await fetch(
      Service.url(service, method), {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: { 'Content-Type': 'application/json' },
      body: json, // body data type must match "Content-Type" header
    })
      .catch(Service.handleError);

  private static getKey = (service: string, method: string, json: string = "") =>
    `${service}-${method}-${json}`;

  private static url(service: string, method: string) {
    return typeof (window) === 'undefined'
      ? ''
      : `http://${window.location.host}/api/${service}/${method}`;
  }
}

