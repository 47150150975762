import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import Service from '../../classes/Service';
import store from '../../states/Store';
import Dates from '../../classes/Dates';
import Quote from '../../classes/Quote';
import "./Charts.css"

const dateFormat = { month: 'short', year: "2-digit" };
const options =
{
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
};

export default function InflationCurve() {
    const { ccy } = useParams<{ ccy: string }>();
    
    const getData = () => {
        const valuationDate: Date = store.MarketData.ValuationDate.getValue();  
        return {
            currency: ccy,
            valuationDate: valuationDate.ToAODate(),
            quotes: store.MarketData.Current.Quotes
                .getValue()
                .filter(quote => quote.Type === "ZCIS" || quote.Type === "CPI")
                .map(quote => Quote.serialize(quote)),
        }
    }

    const createData = async () => {
        // String[][] CreateInflationCurve(
        //     String currency,
        //     String tenor,
        //     Int32 lastCpiDate,
        //     String[] breakEvens)        
        const values = await Service.post('market', 'CreateInflationCurve', JSON.stringify(getData()));
        if (values === null) return;

        const n = values.length;  // Headers + Points
        if (n === 0) return;

        const dates: any[] = Array(n - 1)
        const data: any[] = Array(n - 1)

        for (var i = 1; i < n; i++) {
            dates[i - 1] = Dates.ToString(Number(values[i][0]).FromAODate(), dateFormat);
            data[i - 1] = Number(values[i][1]);
        }

        const dataset = {
            label: "CPI",
            data: data,
            backgroundColor: "#e2431e",
        };

        setData({ labels: dates, datasets: [dataset], options: options });
    }

    const [data, setData] = useState(
        {
            labels: [] as string[],
            datasets: [] as any[],
            options: options
        }
    );

    useEffect(() => {
        if (store.MarketData.Current.Quotes === null ||
            store.MarketData.Current.Quotes
                .getValue()
                .filter(quote => quote.Type === "ZCIS" || quote.Type === "CPI").length < 2) return;

        const service = async () => { await createData(); };
        service();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="chart-container">
            <Bar data={data} />
        </div>
    );
}

