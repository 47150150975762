import React, { useState, useEffect } from 'react';
import store from '../../states/Store';
import Strings from '../../classes/Strings';
import Log, {LogType} from '../../classes/Log';
import './TradesPage.css';
import "./TradeRow.css"

export default function Logs() {
    const [logs, setLogs] = useState(store.Logs.getValue());
    useEffect(() => {
        store.Logs.subscribe(logs => setLogs(logs));
    }, []);

    const getParameters = (log: Log) => Object
        .keys(log)
        .filter(key => !["dateformat","type"].some(function (v) { return key.toLowerCase().indexOf(v) >= 0; }));

    return (
        <div className="TradesPage">
            <ul>
                {logs.slice(-100).map((log: Log, index: number) =>
                    <li className="TradeRow">
                        <div>
                            <div>{index}</div>
                            <div>{LogType[(log as any)["type"]]}</div>
                            {getParameters(log).map(key =>{
                                 const value = (log as any)[key].toString();
                                return value === ''  || value === "0" ? '' : (<div >
                                    <span>{Strings.toTitleCase(key)}</span>
                                    <span>{(log as any)[key].toString()}</span>
                                </div>);
                                })}
                            <div ></div>
                        </div>
                    </li>)}
            </ul>
        </div>
    );
}

