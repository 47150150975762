export default class Factory {
    public static Clone(object: any): any {
        return JSON.parse(JSON.stringify(object));
    }

    public static IsNull(object: any): boolean {
        return object == null || object === undefined;
    }

    public static IsNullOrEmpty(object: any[]): boolean {
        return Factory.IsNull(object) || object.length === 0;
    }

    public static TryBlock(callback: Function, args?: any, errorFunction?: Function, finallyFunction?: Function) {
        try {
            callback.apply(this, args);
        } catch (err) {
            if (errorFunction !== undefined) errorFunction();
        } finally {
            if (finallyFunction !== undefined) finallyFunction();
        }
    }
}
