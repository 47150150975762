import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import store from '../../states/Store';
import Dropdown from './Dropdown';
import "../../css/Dropdown.css"

export default function Currencies() {
  const history = useHistory();
  const [currencies, setCurrencies] = useState(store.StaticData.Currencies.getValue());
  const [currency, setCurrency] = useState(store.MarketData.Currency.getValue());

  useEffect(() => {
    store.StaticData.Currencies.subscribe(currencies => setCurrencies(currencies));
    store.MarketData.Currency.subscribe(currency => setCurrency(currency));
  });

  const handleChange = (currency: string) => {
    history.push(`/${currency.toLowerCase()}${history.location.pathname.substring(4)}`);
    store.MarketData.Currency.next(currency)
  }

  return <Dropdown
    values={currencies}
    key="Currencies"
    value={currency}
    callback={handleChange} />;
}
