import React, { useState } from 'react';
import PropTypes from 'prop-types';

interface IProps {
  value: string|number,
  values: string[]|number[],
  callback: any
}

export default function Dropdown(props: IProps) {
  const [value, setValue]= useState(props.value)

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.callback(event.target.value);
    setValue(event.target.value);
  }

  return <select value={value} onChange={handleChange}>
    {props.values.map((str: any) =>
      <option key={str} value={str}>{str}</option>
    )}
  </select>;
}

Dropdown.propTypes = {
  value: PropTypes.string,
  values: PropTypes.array,
  callback: PropTypes.func
};