import React from 'react';
import PropTypes from 'prop-types';
import Quote from '../../classes/Quote';
import InputNumber from '../blocks/InputNumber';
import { IIndexable } from '../../interfaces/IIndexable';
import '../../css/Table.css';

export default function QuoteTable(props: any) {
  const { headers, legend, properties } = props;
  const quotes = props.Quotes.filter((quote:Quote)=>quote && quote.Type);

  const getHeaders = (headers: string[]) =>
    <tr>
      {headers.map((header: string) => {
        return (<th key={header}>{header}</th>);
      })}
    </tr>

  const getProperties = (quote:Quote, properties: string[]) => 
    properties.map((property: string)=>{
      return(<td key={`${Quote.serialize(quote)}:${property}`}>{(quote as IIndexable<any>)[property]}</td>)
    })

  const getRow = (quote:Quote, properties: string[],  dp: number) =>
    <tr  key={`${Quote.serialize(quote)}`}>
      {getProperties(quote, properties)}
      <td key={`${Quote.serialize(quote)}:Input`}>
        <InputNumber 
          key={`${Quote.serialize(quote)}:Value`}
          callBack={(value: number) => quote.Value = value} 
          value={quote.Value} 
          dp={dp} />
      </td>
    </tr>

  const getRows = (quotes: Quote[], properties: string[]) =>
    quotes.map((quote: Quote) => getRow(quote, properties, Quote.getDP(quote)));

  return !quotes ? null : (
    <form>
      <fieldset>
        <legend>{legend}</legend>
        <table>
          <tbody>
            {getHeaders(headers)}
            {getRows(quotes, properties)}
          </tbody>
        </table>
      </fieldset>
    </form>
  );
}

QuoteTable.propTypes = {
  children: PropTypes.any,
  legend: PropTypes.string,
  headers: PropTypes.array,
  properties: PropTypes.array,
  Quotes: PropTypes.array,
};