import React, { useState } from 'react';
import Numbers from '../../classes/Numbers';

interface IProps{
  value: number,
  callBack: any
}

export default function InputNotional(props: IProps) {
  const [value, setValue] = useState(Numbers.toNotional(props.value));

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value  = Numbers.parse(event.target.value);
    props.callBack(value);
    setValue(Numbers.toNotional(value));
  } 

  return (
      <input
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur} />
    );
}