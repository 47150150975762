import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Numbers from '../../classes/Numbers';

const toString = (value: any, dp: number): string => {
  return Numbers.isNumber(value) ? Number(value).toFixed(dp) : '';
}

export default function InputNumber(props: any) {
  const [value, setValue] = useState(toString(props.value, props.dp));

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value  = parseFloat(event.target.value);
    props.callBack(value);
    setValue(toString(value,  props.dp));
  }

  return (
      <input
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur} />
    );
}

InputNumber.propTypes = {
  value: PropTypes.any,
  callBack: PropTypes.func,
  dp: PropTypes.number,
};