import Instrument from "./Instrument";
import Numbers from "./Numbers";

export default class Quote extends Instrument {
    public Value: number | string = '';

    public static serialize(quote: Quote): string {
         let str = Instrument.serialize(quote);
         if(quote.Value !== '') str = `${str}:${quote.Value.toString()}`;
         return str;
    }

    public static  deserialize(str: string): Quote {
        const params = str.split(':');
        let quote = new Quote();
        quote = Object.assign(quote, Instrument.deserialize(params[0]));
        if (params.length > 1 && Numbers.isNumber(params[1])) quote.Value = Number(params[1]);
        return quote;
    }
}
