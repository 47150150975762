import React from 'react';
import Factory from '../../classes/Factory';
import Serializer from '../../classes/Serializer';
import Service from '../../classes/Service';
import Trade from '../../classes/Trade';

interface IProps {
    index: number,
    trade: Trade
    updateTrade: any
}

// TODO: make it observable to display the Valuation (or the error)
export default function Price(props: IProps) {
    const { index, trade, updateTrade: setTrade } = props;

    const arrayToDictionary = (array: string[][]) => {
        const dict: any = {};
        if(Factory.IsNullOrEmpty(array)) 
          return dict;

        for (var i = 0; i < array.length; i++)
            dict[array[i][0]] = array[i][1];
        return dict;
    }
    
    const priceTrade = async () => {
        try {
            trade.Valuation = {};
            const parameters = Serializer.Clone(trade.Description);

            switch (parameters["SwapType"]) {
                // @ts-ignore
                case "OIS":
                    parameters["tenor"] = '1D';
                    /* falls through */
                case "IRS":
                    //  String[][] EvaluateInterestRateSwap(
                    //      String currency, 
                    //      Double notional, 
                    //      Int32 startDate, 
                    //      Int32 maturityDate, 
                    //      String tenor, 
                    //      String floatingFrequency, 
                    //      String fixedFrequency, 
                    //      Boolean collaterized)
                    trade.Valuation = arrayToDictionary(await Service.post(
                        'pricing',
                        'EvaluateInterestRateSwap',
                        Serializer.serialize(parameters)));
                    break;
                case "DBS":
                    // String[][] EvaluateBasisSwap(
                    //     String currency,
                    //     Double notional,
                    //     Int32 startDate,
                    //     Int32 maturityDate,
                    //     String shortTenor,
                    //     String longTenor,
                    //     Boolean collaterized)
                    trade.Valuation = arrayToDictionary(await Service.post(
                        'pricing',
                        'EvaluateBasisSwap',
                        Serializer.serialize(parameters)));
                    break;
                case "BOB":
                    break;
            }
        } catch (ex: any) {
            trade.Valuation["Error"] = ex instanceof Error ? (ex as Error).message : ex.toString();
        }
        setTrade(trade, index);
    }

    return (
        <div className="Button" key="Price" onClick={priceTrade}>Price</div>
    );
}
