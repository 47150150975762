import { useEffect, useState } from 'react';
import store from '../../states/Store';
import Dates from '../../classes/Dates';
import Strings from '../../classes/Strings';
import Serializer from '../../classes/Serializer';
import Service from '../../classes/Service';
import CreateLeg from './CreateLeg';
import InputArray from '../blocks/InputArray';
import InputNotional from '../blocks/InputNotional';
import TrueFalse from '../blocks/TrueFalse';
import Trade from '../../classes/Trade';
import "./popup.css"
import "./popup-buttons.css"
import "./CreateTrade.css"
import Events from '../../states/Events';

// TODO: should be on a config file or from server
const spot = 'Spot';
const LegTypes = ['Fixed', 'Float', 'OIS']
const Tenors = ['OIS', '1M', '3M', '6M']
const Frequencies = ['1M', '3M', '6M', '1Y']
const LegProperties = ["legType", "frequency", "tenor"];

export default function CreateTrade() {
    const [hidden, setHidden] = useState(true);
    const [maturities, setMaturities] = useState(['1Y'] as string[]);
    const [starts, setStarts] = useState([spot] as string[]);
    const [notional, setNotional] = useState(1e8);
    const [collaterized, setCollaterized] = useState(true);

    const [legs, setLegs] = useState([
        { "legType": "Float", "frequency": "3M", "tenor": "3M" },
        { "legType": "Fixed", "frequency": "6M", "tenor": "6M" }
    ] as any);

    useEffect(() => {
        store.UI.CreateTrade.subscribe(display => {
            if(display){
                Events.registerKeyDown('Enter', Create);
                Events.registerKeyDown('Escape', Close);
            } else{
                Events.unregisterKeyDown('Enter');
                Events.unregisterKeyDown('Escape');
            }
            setHidden(!display);
        });
    }, []);

    const handleCloseEvent = () => store.UI.CreateTrade.next(false);
    const handleLegChange = (index: number, key: string, value: string) => {
        legs[index][key] = value;
        setLegs(legs);
    }

    const getDate = async (dict: any, valuationDate: Date, currency: string, parameter: string, input: string): Promise<Date> => {
        let date: Date = new Date();
        if (isTenor(input)) {
            dict[parameter] = input.toUpperCase();
            date = dict[`${parameter}Date`]
                = await getTenorNext(valuationDate, input);
        }
        else if (Dates.TryParse(input, date)) {
            dict[parameter] = '';
            dict[`${parameter}Date`] = Dates.ToAODate(date);
        }
        else
            throw new Error(`Unknown maturity: "${input}"`);
        return date;
    }

    const getSpotDate = async (valuationDate: Date, currency: string) => {
        return await Service.post("data", "SpotDate", Serializer.serialize(
            {
                "currency": currency,
                "valuationDate": valuationDate
            }
        ), true);
    }

    const getTenorNext = async (valuationDate: Date, tenor: string) => 
         await Service.post("data", "TenorNext", Serializer.serialize(
                {
                    "valuationDate": valuationDate,
                    "tenor": tenor
                }
            ), true);

    const getAdjustDate = async (valuationDate: Date, currency: string) => 
        
            await Service.post("data", "AdjustDate", Serializer.serialize(
                {
                    "valuationDate": valuationDate,
                    "currency": currency
                }
            ), true);

    const handleCreateEvent = async () => Create();

    const Close = () => store.UI.CreateTrade.next(false);

    const Create = async () => {
        let count: number = 0;
        const portfolio = store.MarketData.Current.Portfolio.getValue();
        const currency = store.MarketData.Currency.getValue();
        const valuationDate = store.MarketData.ValuationDate.getValue();

        for (var start of starts.length === 0 ? [spot] : starts) {
            for (var maturity of maturities) {
                const trade = new Trade();
                trade.Description["currency"] = currency;
                trade.Description["valuationDate"] = valuationDate;
                trade.Description["notional"] = notional;

                let startDate: Date = new Date();

                if (start === '' || Strings.toTitleCase(start) === spot) {
                    start = trade.Description["start"] = spot;
                    startDate
                        = trade.Description["startDate"]
                        = await getSpotDate(valuationDate, currency);
                }
                else
                    startDate = 
                        await getAdjustDate(
                            await getDate(trade.Description, valuationDate, currency, "start", start), currency);

                // unadjusted MaturityDate
                await getDate(trade.Description, startDate, currency, "maturity", maturity);

                trade.Description["collaterized"] = collaterized;

                legs.forEach((leg: { [x: string]: string; }, index: number) => {
                    const name = index === 0 ? "first" : "second"; // leg["legType"].toLowerCase();
                    LegProperties.forEach(property => {
                        trade.Description[`${name}${Strings.toTitleCase(property)}`] = leg[property];
                    });
                });

                switch (trade.Description["SwapType"] = Trade.getType(trade)) {
                    case "IRS":
                        trade.Description["fixedFrequency"] = trade.Description["secondFrequency"];
                        trade.Description["floatingFrequency"] = trade.Description["firstFrequency"];
                        trade.Description["tenor"] = trade.Description["firstTenor"];
                        break;
                    case "DBS":
                        trade.Description["shortTenor"] = trade.Description["firstTenor"];
                        trade.Description["longTenor"] = trade.Description["secondTenor"];
                        break;
                    case "OIS":
                        trade.Description["fixedFrequency"] = trade.Description["secondFrequency"];
                        trade.Description["floatingFrequency"] = trade.Description["firstFrequency"];
                        trade.Description["tenor"] = 'OIS';
                        break;
                }
                portfolio.push(trade);
                count = count + 1;
            }
        }
        if (count > 0) {
            store.MarketData.Current.Portfolio.next(portfolio);
            Close();
        }
    }

    const TenorUnits: string[] = ['D', 'W', 'M', 'Y']; // TODO: config should come from the server
    const isTenor = (tenor: string) => {
        const str = tenor.toUpperCase();
        const n: number = str.length;
        return (TenorUnits.indexOf(str.substring(n - 1, n)) > -1 && !isNaN(parseInt(str.substring(n - 2))));
    }

    const getProperties = (): any[][] => [
        [
            { name: "Starts", element: <InputArray callBack={setStarts} value={starts} /> },
            { name: "Notional", element: <InputNotional callBack={setNotional} value={notional} /> },
        ],
        [
            { name: "Maturities", element: <InputArray callBack={setMaturities} value={maturities} /> },
            { name: "Collaterized", element: <TrueFalse callback={setCollaterized} value={collaterized} /> },
        ]
    ];

    return hidden ? null : (
        <div className="popup">
            <div className="CreateTrade">
                <div>
                    {[0, 1].map((index: number) => {
                        return (<div key={`SwapLeg_${index}`}>
                            <div>
                                <table>
                                    <tbody>
                                        {getProperties()[index].map(property =>
                                            <tr key={`SwapLeg_${property.name}`}>
                                                <td>{property.name}</td>
                                                <td>{property.element}</td>
                                            </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <CreateLeg
                                index={index}
                                properties={[
                                    { name: "legType", value: legs[index]["legType"], values: LegTypes },
                                    { name: "tenor", value: legs[index]["tenor"], values: Tenors },
                                    { name: "frequency", value: legs[index]["frequency"], values: Frequencies }
                                ]}
                                callback={handleLegChange} />
                        </div>);
                    })}
                </div>
                <div>
                    <table className="popup-buttons">
                        <tbody >
                            <tr>
                                <td onClick={handleCreateEvent}>Create</td>
                                <td onClick={handleCloseEvent}>Close</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}