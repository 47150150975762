import Factory from "./Factory";

export default class Dates {
    //#region Static properties
    public static oneDay = 1000 * 60 * 60 * 24;
    public static dateFormat = { day: '2-digit', month: 'short', year: 'numeric' };
    public static refDate = Date.UTC(1900, 0, 1);
    public static shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    public static longMonths = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    //#endregion

    public static TryParse(str: string, date: Date): boolean {
        try {
            date = Dates.Parse(str);
            return Dates.isValidDate(date);
        }
        catch (ex) {
            return false;
        }
    }

    public static Parse(str: string): Date {
        let param: string[] = [];

        if (str.indexOf("-") !== -1)
            param = str.split("-");
        else if (str.indexOf("/") !== -1)
            param = str.split("/");
        else if (str.indexOf(".") !== -1)
            param = str.split(".");
        else if (str.indexOf(" ") !== -1)
            param = str.split(" ");
        else {
            const lengthYear = str.length - 4;
            param = [(str.substring(0, lengthYear)), str.substring(lengthYear, lengthYear + 2), str.substring(lengthYear + 2, lengthYear + 4)];
        }

        if (param.length !== 3) throw new Error(`Date parsing failed for :${str}`);

        let idx: number = -1;

        idx = Dates.shortMonths.indexOf(param[1]);
        if (idx !== -1) param[1] = (idx + 1).toString();

        idx = Dates.longMonths.indexOf(param[1]);
        if (idx !== -1) param[1] = (idx + 1).toString();

        let century = new Date().getFullYear().toString().substring(0, 2);
        let year: string, day: string;

        if (param[0].length > param[2].length) {
            year = param[0];
            day = param[2];
        } else {
            year = param[2];
            day = param[0];
        }
        if (year.length === 4) century = "";
        return new Date(parseInt(`${century}${year}`, 10), parseInt(param[1], 10) - 1, parseInt(day, 10));
    }

    public static ToString(date: Date, dateFormat?: any): string {
        if (typeof date === 'string') date = new Date(date);
        return new Date(date)
            .toLocaleDateString('en-GB', Factory.IsNull(dateFormat) ? Dates.dateFormat : dateFormat)
            .replace(' ', '-').replace(' ', '-');
    }

    public static ToUTC(date: Date) {
        if (typeof date === 'string') date = new Date(date);
        return new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()));
    }

    public static ToAODate(date: Date): number {
        if (typeof date === 'string') date = new Date(date);
        return Math.round((this.ToUtcNumber(date) - this.refDate) / this.oneDay) + 2;
    };

    public static FromAODate(date: number): Date {
        return new Date((date.valueOf() - 2) * this.oneDay + this.refDate);
    };

    public static ToUtc(date: Date): Date {
        if (typeof date === 'string') date = new Date(date);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    private static ToUtcNumber(date: Date): number {
        if (typeof date === 'string') date = new Date(date);
        return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    }

    public static Diff = (date1: Date, date2: Date): number => (date1.getTime() - date2.getTime()) / 1000; // seconds

    private static isValidDate = (date: any) => date instanceof Date && !isNaN(date as any);
}
