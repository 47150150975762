import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import Service from '../../classes/Service';
import store from '../../states/Store';
import Dates from '../../classes/Dates';
import Quote from '../../classes/Quote';
import "./Charts.css"

const colors = ["#e2431e", "#d3362d", "#e7711b", "#e49307"];
const dateFormat = { month: 'short', year: "2-digit" };
const options =
{
  animation: false,
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    xAxes: [{
      display: true,
      gridLines: {
        display: false,
      },
    }],
    yAxes: [{
      display: true,
      gridLines: {
        display: true,
        color: "#ffffff"
      },
    }]
  }
};

export default function InterestCurves() {
  const { ccy } = useParams<{ ccy: string }>();

  const [data, setData] = useState(
    {
      labels: [] as string[],
      datasets: [] as any[],
      options: options
    }
  );

  useEffect(() => {
    const synthetics = store.MarketData.Current.SyntheticQuotes.getValue();
    if (synthetics === null || synthetics.length < 1) return;
    const service = async () => { await createData(); };
    service();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getData = () => {
    const synthetics: Quote[][] = store.MarketData.Current.SyntheticQuotes.getValue();
    const valuationDate: Date = store.MarketData.ValuationDate.getValue();
    return {
      currency: ccy,
      valuationDate: valuationDate.ToAODate(),
      instrumentsOIS: synthetics[0].map(quote => Quote.serialize(quote)),
      instruments1M: synthetics[1].map(quote => Quote.serialize(quote)),
      instruments3M: synthetics[2].map(quote => Quote.serialize(quote)),
      instruments6M: synthetics[3].map(quote => Quote.serialize(quote)),
      fixings: store.MarketData.Current.Quotes
        .getValue()
        .filter(quote => quote.Type === 'FIX' && quote.Value !== '')
        .map(quote => Quote.serialize(quote)),
      slow: true
    }
  }

  const createData = async () => {
    const values = await Service.post('market', 'CreateInterestCurves', JSON.stringify(getData()));
    if (values === null) return;
    const n = values.length;  // Headers + Points
    if (n === 0) return;
    const m = values[0].length; // Dates + Tenors
    const dates: any[] = Array(n - 1)
    const datasets = [];

    for (var i = 1; i < n; i++)
      dates[i - 1] = Dates.ToString(Number(values[i][0]).FromAODate(), dateFormat);

    for (var j = 1; j < m; j++) {
      const color = colors[j - 1]
      const data: any[] = Array(n - 1);
      const label: string = `Curve ${values[0][j].replace("1D", "OIS")}`;

      for (i = 1; i < n; i++)
        data[i - 1] = Math.round(Number(values[i][j]) * 1000) * 0.001;

      datasets.push({
        label: label,
        data: data,
        showLine: true,
        fill: false,
        pointRadius: 0,
        borderColor: color
      });
    }

    setData({ labels: dates, datasets: datasets, options: options });
  }


  return (
    <div className="chart-container">
      <Line data={data} />
    </div>
  );
}

