import React, { useState, useEffect } from 'react';
import store from '../../states/Store';
import InputDate from './InputDate';

export default function ValuationDate() {
  const [valuationDate, setValuationDate] = useState(store.MarketData.ValuationDate.getValue());

  useEffect(() => {
    store.MarketData.ValuationDate.subscribe((ValuationDate: Date) => {
      setValuationDate(ValuationDate);
    });
  });

  const handleChange = (date: Date) => {
    store.MarketData.ValuationDate.next(date);
  }

  return (
    <InputDate
      callback={handleChange}
      date={valuationDate}
    />
  );
}