import Dates from "./Dates";
import { IIndexable } from "../interfaces/IIndexable";

export default class Instrument {
    //#region Properties
    public Currency: string = "";
    public Type: string = "";
    public Start: string = "";
    public Maturity: string = "";
    public Tenor: string = "";
    public FixedFrequency: string = "";
    public FloatingFrequency: string = "";
    public ValuationDate: Date = new Date();

    private dateFormat = { month: 'short', year: "2-digit" };
    //#endregion

    public static deserialize(value: string): Instrument {
        const instrument = new Instrument();
        let split = value.split("@");
        instrument.ValuationDate = split.length === 1 ? new Date() : Dates.FromAODate(parseFloat(split[1]))
        split = split[0].split(".");
        const n = split.length;

        instrument.Currency = split[0];
        instrument.Type = split[1];
        if (n < 3) {
            instrument.Maturity = Dates.ToString(instrument.ValuationDate, instrument.dateFormat);
            return instrument; // e.g. AUD.CPI@42551
        }

        instrument.Maturity = split[n - 1];

        if (instrument.Type === 'OIS') {
            instrument.Tenor = 'OIS';
            instrument.FloatingFrequency = instrument.FixedFrequency = '1Y'
            return instrument;
        }

        if (n === 3) {
            if (instrument.Type === 'DEPO') instrument.Tenor = instrument.Maturity
            return instrument; // e.g. "AUD.DEPO.3M", "AUD.FX.Spot", ...
        }

        instrument.Tenor = split[n === 5 ? n - 3 : n - 2];

        if (instrument.Tenor.indexOf('/') !== -1) {
            const split2 = instrument.Tenor.split('/')
            instrument.Tenor = split2[0];
            instrument.FloatingFrequency = split2[1]
        }

        if (n === 5) instrument.FixedFrequency = split[n - 2];
        return instrument;
    }

    public static serialize(instrument: Instrument): string {
        const today = new Date();
        const buffer: string[] = [];
        const push = Instrument.push;
        push(buffer, instrument.Currency)
        push(buffer, instrument.Type)
        if (instrument.Type !== 'OIS') {
            if (instrument.Tenor !== '')
                if (instrument.FloatingFrequency !== '' && instrument.Tenor !== instrument.FloatingFrequency)
                    push(buffer, instrument.Tenor + '/' + instrument.FloatingFrequency)
                else
                    push(buffer, instrument.Tenor)

            push(buffer, instrument.FixedFrequency)
        }

        if (!(['DEPO', 'FIX', 'FX'].indexOf(instrument.Type) !== -1 && instrument.Tenor === instrument.Maturity)) push(buffer, instrument.Maturity)
            let str = buffer.join(".");

        if (instrument.ValuationDate !== today) 
            str = `${str}@${Dates.ToAODate(instrument.ValuationDate).toString()}`;

        return str;
    }

    public static getDP(instrument: Instrument ) {
        return instrument.Maturity === 'Spot' ? 4 : ({
            'DEPO': 4,
            'FUT': 4,
            'OIS': 4,
            'IRS': 4,
            'DBS': 2,
            'FX': 4,
            'XCS': 2,
            'CPI': 2,
            'ZCIS': 4
        } as IIndexable<any>)[instrument.Type === '' ? 'DEPO' : instrument.Type]
    }

    private static push(acc: string[], property: string): string[] {
        if (property !== '') acc.push(property);
        return acc;
    }
}
