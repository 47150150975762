import { BehaviorSubject } from 'rxjs';
import { IIndexable } from '../interfaces/IIndexable';
import Trade from "../classes/Trade";
import Quote from "../classes/Quote";
import Service from '../classes/Service';
import Log from '../classes/Log';

// Simplify the data models
// Simplify the initialization of the data
// Hook event and RestAPI to those data

export const defaultCurrency: string = "AUD";
export const getDefaultQuotes = (currency: string): Quote[] => {
    const parse = Quote.deserialize;
    return [
        "DEPO.OIS",
        "DEPO.1M",
        "DEPO.2M",
        "DEPO.3M",
        "DEPO.4M",
        "DEPO.5M",
        "DEPO.6M",
        "FIX.OIS",
        "FIX.1M",
        "FIX.2M",
        "FIX.3M",
        "FIX.4M",
        "FIX.5M",
        "FIX.6M",
        "OIS.1M",
        "OIS.2M",
        "OIS.3M",
        "OIS.4M",
        "OIS.5M",
        "OIS.6M",
        "OIS.7M",
        "OIS.8M",
        "OIS.9M",
        "OIS.10M",
        "OIS.11M",
        "OIS.1Y",
        "OIS.2Y",
        "OIS.3Y",
        "OIS.4Y",
        "OIS.5Y",
        "OIS.6Y",
        "OIS.7Y",
        "OIS.8Y",
        "OIS.9Y",
        "OIS.10Y",
        "OIS.11Y",
        "OIS.12Y",
        "OIS.15Y",
        "OIS.20Y",
        "OIS.25Y",
        "OIS.30Y",
        "OIS.40Y",
        "OIS.50Y",
        "IRS.3M.1Y",
        "IRS.3M.2Y",
        "IRS.3M.3Y",
        "IRS.3M.4Y",
        "IRS.3M.5Y",
        "IRS.3M.6Y",
        "IRS.3M.7Y",
        "IRS.3M.8Y",
        "IRS.3M.9Y",
        "IRS.3M.10Y",
        "IRS.3M.11Y",
        "IRS.3M.12Y",
        "IRS.3M.15Y",
        "IRS.3M.20Y",
        "IRS.3M.25Y",
        "IRS.3M.30Y",
        "IRS.3M.40Y",
        "IRS.3M.50Y",
        "IRS.6M.1Y",
        "IRS.6M.2Y",
        "IRS.6M.3Y",
        "IRS.6M.4Y",
        "IRS.6M.5Y",
        "IRS.6M.6Y",
        "IRS.6M.7Y",
        "IRS.6M.8Y",
        "IRS.6M.9Y",
        "IRS.6M.10Y",
        "IRS.6M.11Y",
        "IRS.6M.12Y",
        "IRS.6M.15Y",
        "IRS.6M.20Y",
        "IRS.6M.25Y",
        "IRS.6M.30Y",
        "IRS.6M.40Y",
        "IRS.6M.50Y",
        "DBS.3M.6M.3M",
        "DBS.3M.6M.6M",
        "DBS.3M.6M.9M",
        "DBS.3M.6M.1Y",
        "DBS.3M.6M.18M",
        "DBS.3M.6M.2Y",
        "DBS.3M.6M.3Y",
        "DBS.3M.6M.4Y",
        "DBS.3M.6M.5Y",
        "DBS.3M.6M.6Y",
        "DBS.3M.6M.7Y",
        "DBS.3M.6M.8Y",
        "DBS.3M.6M.9Y",
        "DBS.3M.6M.10Y",
        "DBS.3M.6M.11Y",
        "DBS.3M.6M.12Y",
        "DBS.3M.6M.15Y",
        "DBS.3M.6M.20Y",
        "DBS.3M.6M.25Y",
        "DBS.3M.6M.30Y",
        "DBS.3M.6M.40Y",
        "DBS.3M.6M.50Y",
        "DBS.3M.6M.60Y",
        "FX.ON",
        "FX.TN",
        "FX.Spot",
        "FX.2W",
        "FX.3W",
        "FX.1M",
        "FX.2M",
        "FX.3M",
        "FX.4M",
        "FX.5M",
        "FX.6M",
        "FX.7M",
        "FX.8M",
        "FX.9M",
        "FX.10M",
        "FX.11M",
        "FX.1Y",
        "XCS.2Y",
        "XCS.3Y",
        "XCS.4Y",
        "XCS.5Y",
        "XCS.6Y",
        "XCS.7Y",
        "XCS.9Y",
        "XCS.10Y",
        "XCS.15Y",
        "XCS.20Y",
        "XCS.30Y",
        "ZCIS.1Y",
        "ZCIS.2Y",
        "ZCIS.3Y",
        "ZCIS.4Y",
        "ZCIS.5Y",
        "ZCIS.6Y",
        "ZCIS.7Y",
        "ZCIS.8Y",
        "ZCIS.9Y",
        "ZCIS.10Y",
        "ZCIS.11Y",
        "ZCIS.12Y",
        "ZCIS.13Y",
        "ZCIS.14Y",
        "ZCIS.15Y",
        "ZCIS.16Y",
        "ZCIS.17Y",
        "ZCIS.18Y",
        "ZCIS.19Y",
        "ZCIS.20Y",
    ].map((quote: string) => { return parse(`${currency}.${quote}`) });
}

export interface IStorable {
    save(): void;
    load(): void;
    getPath(): string;
    setPath(path: string): Object;
}

export class StorableBehaviorSubject<T> extends BehaviorSubject<T> implements IStorable {
    protected path: string = "";

    [property: string]: Object;

    public getPath = () => this.path;

    public setPath = (path: string) => {
        this.path = path;
        this.load();
        this.subscribe(this.save);
        return this;
    }

    public save = () => localStorage.setItem(this.getPath(), JSON.stringify(this.getValue()));

    public load = () => this.next(JSON.parse(localStorage.getItem(this.getPath()) ?? "") as T);
}

export class Store {
    protected path: string = "";

    [property: string]: Object;

    constructor(){
        this.load();
    }

    public getValue = (property: string) => (this as unknown as IIndexable<Object>)[property];

    public getPath = () => this.path;

    public setPath = (path: string) => {
        this.path = path;
        Object.keys(this).forEach((property: string) => {
            const setPath = (this.getValue(property) as IStorable)?.setPath;
            if (setPath !== undefined) {
                setPath(`${path}.${property}`);
            }
        });
        this.load();
        return this;
    }

    public loadProperty = (property: string) => {
        const obj = this.getValue(property);
        if (obj === null || this.path === "" || property === "path" || typeof obj === 'function') return;
        const path = `${this.path}.${property}`;
        const load = (obj as IStorable).load;
        if (load !== undefined) {
            load();
        } else {
            const str = localStorage.getItem(path)
            if (str === null) return;
            const value = JSON.parse(str);
            if (obj instanceof BehaviorSubject)
                obj.next(value);
            else
                this[property] = value;
        }
    }

    public load = () => {
        if (this.path === "") return;
        Object.keys(this).forEach((property: string) => this.loadProperty(property));
    }

    public saveProperty = (property: string) => {
        const obj = this.getValue(property);
        if (obj === null || this.path === "" || property === "path" || typeof obj === 'function') return;
        const path = `${this.path}.${property}`;
        const save = (obj as IStorable).save;
        if (save !== undefined) {
            save();
            return;
        }
        const value = JSON.stringify(obj instanceof BehaviorSubject
            ? (obj as BehaviorSubject<Object>).getValue()
            : obj);
        localStorage.setItem(path, value);
    }

    public save = () => {
        if (this.path === "") return;
        Object.keys(this).forEach((property: string) => this.saveProperty(property));
    }
}

export class UI extends Store {
    Disclaimer: boolean = true;
    CreateTrade: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    Busy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
};

export class User extends Store {
    Username: BehaviorSubject<string> = new BehaviorSubject<string>("");
};

export class StaticData extends Store {
    Currencies: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
}

export class Cache extends Store {
    Dictionary: BehaviorSubject<{}> = new BehaviorSubject<{}>({});
}

export class Current extends Store {
    Quotes: BehaviorSubject<Quote[]> = new BehaviorSubject<Quote[]>(getDefaultQuotes(defaultCurrency));
    SyntheticQuotes: BehaviorSubject<Quote[][]> = new BehaviorSubject<Quote[][]>([[]]);
    InterestRateCurves: BehaviorSubject<string[][]> = new BehaviorSubject<string[][]>([[]]);
    InflationCurve: BehaviorSubject<string[][]> = new BehaviorSubject<string[][]>([[]]);
    Portfolio: BehaviorSubject<Trade[]> = new BehaviorSubject<Trade[]>([]);
}

export class MarketData extends Store {
    ValuationDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
    Currency: BehaviorSubject<string> = new BehaviorSubject<string>(defaultCurrency);
    Current: Current = new Current();
    Quotes: { [property: string]: Quote[] } = {};
    SyntheticQuotes: { [property: string]: Quote[][] } = {};
    InterestRateCurves: { [property: string]: string[][] } = {};
    InflationCurves: { [property: string]: string[][] } = {};
    Portfolios: { [property: string]: Trade[] } = {};

    constructor() {
        super();

        this.Currency.subscribe(currency => {
            let quotes = this.Quotes[currency];
            if (quotes === undefined || quotes.length === 0) quotes = getDefaultQuotes(currency);
            this.Current.Quotes.next(quotes);
            this.Current.SyntheticQuotes.next(this.SyntheticQuotes[currency] ?? []);
            this.Current.InterestRateCurves.next(this.InterestRateCurves[currency] ?? []);
            this.Current.InflationCurve.next(this.InflationCurves[currency] ?? []);
            this.Current.Portfolio.next(this.Portfolios[currency] ?? []);
            this.saveProperty('Currency');
        });
        this.Current.Quotes.subscribe(quotes => {
            const currency = this.Currency.getValue()
            if (quotes !== null && quotes.length > 0 && quotes[0].Currency === currency) {
                this.Quotes[currency] = quotes;
                this.Current.saveProperty('Quotes');
                this.saveProperty('Quotes');
            }
        });
        this.Current.SyntheticQuotes.subscribe(quotes => {
            this.SyntheticQuotes[this.Currency.getValue()] = quotes;
            this.Current.saveProperty('SyntheticQuotes');
            this.saveProperty('SyntheticQuotes');
        });
        this.Current.InterestRateCurves.subscribe(data => {
            this.InterestRateCurves[this.Currency.getValue()] = data;
            this.Current.saveProperty('InterestRateCurves');
            this.saveProperty('InterestRateCurves');
        });
        this.Current.InflationCurve.subscribe(data => {
            this.InflationCurves[this.Currency.getValue()] = data;
            this.Current.saveProperty('InflationCurve');
            this.saveProperty('InflationCurves');
        });
        this.Current.Portfolio.subscribe(data => {
            this.Portfolios[this.Currency.getValue()] = data;
            this.Current.saveProperty('Portfolio');
            this.saveProperty('Portfolios');
        });
    }
};

export class Logs extends BehaviorSubject<Log[]>{
    constructor(){
        super([]);
    }
    public push(log: Log){
        const array = this.getValue();
        array.push(log);
        this.next(array);
    } 
}

export class Data extends Store {
    Service: Cache = new Cache();
    UI: UI = new UI();
    User: User = new User();
    StaticData: StaticData = new StaticData();
    MarketData: MarketData = new MarketData();
    Logs: Logs = new Logs();
}

const store = new Data().setPath("au.com.myquant");
export default store;

Service.post('data', 'GetCurrencies', '', true)
    .then(currencies => store.StaticData.Currencies.next(currencies))
    .catch(error => console.error(error));

