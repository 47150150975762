import Dates from "./Dates";
import Numbers from "./Numbers";

export default class Serializer {
  public static serialize(dictionary: any): string {
    const description: any = {};
    Serializer.getKeys(dictionary).forEach((key: string) => {
      const value = dictionary[key];
      description[key] = value instanceof Date
        ? Dates.ToAODate(value)
        : value;
    });
    return JSON.stringify(description);
  }

  public static deserialize(json: string): {} {
    const dictionary = JSON.parse(json);
    Serializer.getKeys(dictionary).forEach((key) => {
      const value = dictionary[key];
      dictionary[key] = Numbers.isNumber(value) && key.indexOf("date") !== -1
        ? Dates.FromAODate(value)
        : value;
    });
    return dictionary;
  }

  public static Clone(object: any): any {
    return Serializer.deserialize(Serializer.serialize(object));
}

  private static getKeys(dictionary: {}): string[] {
    return Object
      .keys(dictionary)
      .filter(key => !["dateFormat"].includes(key));
  }
}

