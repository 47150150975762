import Factory from "./Factory";

export default class Trade {
    public Description: any = {};
    public Valuation: any = {};

    public static getType(trade: Trade) {
        if (Factory.IsNullOrEmpty(trade?.Description)) return "";
        switch (trade.Description["firstLegType"] + "/" + trade.Description["secondLegType"]) {
            case "Fixed/Float":
            case "Float/Fixed":
                return "IRS";
            case "Float/Float":
                return "DBS";
            case "Float/OIS":
            case "OIS/Float":
                return "BOB";
            case "Fixed/OIS":
            case "OIS/Fixed":
                return "OIS";
        }
    }
}
