import React from 'react';

export default () =>
    <div>
        <h1>Software license</h1>
        <p>These license terms are an agreement between <b>myQuant Pty. Ltd.</b> and you. They apply to this Software. "Software" means the software that you selected for download, install or use from myQuant or its authorized licensees, any updates or patches, user manuals, and other documentation provided to you by myQuant under this Agreement.</p>
        <p>Subject to the terms and conditions of this Agreement, <b>myQuant grants you a non-perpetual, non-exclusive, non-transferable, limited license without license fees to reproduce and use internally the Software complete and unmodified for the sole purpose of using myQuant website and softwares.</b></p>
        <p><b>This Software is copyrighted. This Software and all associated intellectual property rights is retained by myQuant and/or its licensors. Unless enforcement is prohibited by applicable law, you may not modify, decompile, or reverse engineer the Software</b>. You acknowledge that the Software is developed for general use, and does not constitute advice of any kind and must not be relied upon as such. myQuant disclaims any express or implied warranty of fitness for such uses. No right, title or interest in or to any trademark, service mark, logo or trade name of myQuant or its licensors is granted under this Agreement.</p>
        <p>THE SOFTWARE IS LICENSED <b>"AS-IS."</b> YOU BEAR THE RISK OF USING IT. MYQUANT GIVES NO EXPRESS WARRANTIES, GUARANTEES OR CONDITIONS. TO THE EXTENT PERMITTED UNDER YOUR LOCAL LAWS, MYQUANT EXCLUDES THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
        <p>This Agreement is effective until terminated. You may terminate this Agreement at any time by destroying all copies of Software. This Agreement will terminate immediately without notice from myQuant if you fail to comply with any provision of this Agreement. Either party may terminate this Agreement immediately should any Software become, or in either party's opinion be likely to become, the subject of a claim of infringement of any intellectual property right. Upon termination, you must destroy all copies of Software.</p>
    </div>

