import React, { useState } from 'react';
import Strings from '../../classes/Strings';

interface IProps {
  value: boolean,
  callback: any
}

export default function Dropdown(props: IProps) {
  const [value, setValue]= useState(props.value)

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const result = event.target.value === 'True' ? true : false
    props.callback(result);
    setValue(result);
  }

  return <select value={Strings.toTitleCase(value.toString())} onChange={handleChange}>
    {['True','False'].map((str: string) =>
      <option key={str} value={str}>{str}</option>
    )}
  </select>;
}