import React from 'react';
import PropTypes from 'prop-types';
import Quote from '../../classes/Quote';
import InputNumber from '../blocks/InputNumber';
import './BasisTable.css';
import '../../css/Table.css';
import store from '../../states/Store';

const dp: number = 2;
const headers: string[] = ['Term', '0s3s', '1s3s', '3s6s'];
const tenors: string[] = ['OIS', '1M', '3M'];
const pairs: string[] = ['OIS.3M', '1M.3M', '3M.6M'];
const terms: string[] = [
  "3M",
  "6M",
  "9M",
  "1Y",
  "18M",
  "2Y",
  "3Y",
  "4Y",
  "5Y",
  "6Y",
  "7Y",
  "8Y",
  "9Y",
  "10Y",
  "11Y",
  "12Y",
  "15Y",
  "20Y",
  "25Y",
  "30Y",
  "40Y",
  "50Y",
  "60Y",
];

const createTable = (quotes: Quote[]): Quote[][] | undefined => {
  if (quotes.length === 0) return undefined;
  const parse = Quote.deserialize;
  const currency = store.MarketData.Currency.getValue();

  const n: number = terms.length;
  const m: number = tenors.length;

  let values: Quote[][] = new Array<Quote[]>(n);
  for (var i = 0; i < n; i++) {
    values[i] = new Array<Quote>(m);

    for (var j = 0; j < m; j++)
      values[i][j] = parse(`${currency}.DBS.${pairs[j]}.${terms[i]}`);
  }

  quotes.forEach(quote => {
    const row = terms.indexOf(quote.Maturity);
    const column = tenors.indexOf(quote.Tenor);
    if (row >= 0 && column >= 0) values[row][column] = quote;
  });

  return values;
}

export default function BasisTable(props: any) {
  const quotes: Quote[][] = createTable(props.Quotes.filter(
    (quote: Quote) => quote && quote.Type && quote.Type === 'DBS')) ?? [[]];

  const getHeaders = (headers: string[]) =>
    <tr>
      {headers.map((header: string) => {
        return (<th key={header}>{header}</th>);
      })}
    </tr>

  const getInput = (quote: Quote) =>
    <td key={`${Quote.serialize(quote)}:Input`}>
      <InputNumber
        key={`${Quote.serialize(quote)}:Value`}
        callBack={(value: number) => quote.Value = value}
        value={quote.Value}
        dp={dp} />
    </td>

  const getRow = (term: string, quotes: Quote[]) =>
    <tr className="BasisTable" key={`BasisTable.${term}`}>
      <td>{term}</td>
      {quotes.map((quote: Quote) => {
        return getInput(quote);
      })}
    </tr>

const getRows = (terms: string[], quotes: Quote[][]) => 
  terms.map((term: string, row: number) => getRow(term, quotes![row]));

  return (
    <form className="BasisTable">
      <fieldset>
        <legend>
          Basis Spreads
        </legend>
        <table>
          <tbody>
            {getHeaders(headers)}
            {getRows(terms, quotes)}
          </tbody>
        </table>
      </fieldset>
    </form>
  );
}

BasisTable.propTypes = {
  Quotes: PropTypes.array
};