import React from 'react';
import Quote from '../../classes/Quote';
import store from '../../states/Store';
import './Load.css';

export default function Load() {
    const replaceAll = (value: string, search: string, replacement: string) => {
        return value.replace(new RegExp(search, "g"), replacement);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const reader = new FileReader();
            if (!event.target.files) return;
            const file = event.target.files[0];
            console.log(file)

            reader.onload = (e: any) => {
                const quotes: Quote[] = [];
                const currency = store.MarketData.Currency.getValue();
                const parse = Quote.deserialize;
                replaceAll(replaceAll(e.target.result, "\r", ""), "\n", "|")
                    .split("|")
                    .forEach((str: string) => {
                        const quote = parse(str);
                        if (quote.Currency === currency) quotes.push(quote);
                    });
                store.MarketData.Current.Quotes.next(quotes);
            };
            reader.readAsText(file);
        } catch (error) {
            console.log(error) // Should display modal form, but not yet implemented.
        }
    }

    return (
        <div className="file-input">
            <span>Load</span>
            <input type="file" onChange={handleChange} name="Load" />
        </div>);
}
