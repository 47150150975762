export enum LogType {
    Trace,
    Info,
    Error,
    Fatal
}
export default class Log {
    private type: LogType;
    private timestamp: string;
    private service: string;
    private method: string;
    private elapsedTime: number;
    private message: string;
    private error: number;

    constructor(type: LogType, service: string, method: string, elapsedTime: number, message: string = "", error: number = 0) {
        this.type = type;
        this.timestamp = new Date().toISOString();
        this.service = service;
        this.method = method
        this.elapsedTime = elapsedTime
        this.message = message;
        this.error = error;
    }
}
