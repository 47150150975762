import React from 'react';
import store from '../../states/Store';
import Quote from '../../classes/Quote';

const getQuotes = ():Quote[] =>  {
        const parse = Quote.deserialize;
        return [
            "AUD.DEPO.OIS:0.1",
            "AUD.DEPO.1M:0.23",
            "AUD.DEPO.2M:0.32",
            "AUD.DEPO.3M:0.37",
            "AUD.DEPO.4M:0.45",
            "AUD.DEPO.5M:0.54",
            "AUD.DEPO.6M:0.61",
            "AUD.OIS.1M:0.095",
            "AUD.OIS.2M:0.095",
            "AUD.OIS.3M:0.0925",
            "AUD.OIS.4M:0.09",
            "AUD.OIS.5M:0.09",
            "AUD.OIS.6M:0.09",
            "AUD.OIS.9M:0.09",
            "AUD.IRS.3M.1Y:0.4",
            "AUD.IRS.3M.2Y:0.5125",
            "AUD.IRS.3M.3Y:0.665",
            "AUD.IRS.6M.4Y:1.005",
            "AUD.IRS.6M.5Y:1.1138",
            "AUD.IRS.6M.6Y:1.2088",
            "AUD.IRS.6M.7Y:1.2913",
            "AUD.IRS.6M.8Y:1.3638",
            "AUD.IRS.6M.9Y:1.4288",
            "AUD.IRS.6M.10Y:1.49",
            "AUD.IRS.6M.12Y:1.5975",
            "AUD.IRS.6M.15Y:1.7075",
            "AUD.IRS.6M.20Y:1.8025",
            "AUD.IRS.6M.25Y:1.855",
            "AUD.IRS.6M.30Y:1.8575",
            "AUD.DBS.OIS.3M.1Y:30.5",
            "AUD.DBS.OIS.3M.2Y:30.75",
            "AUD.DBS.OIS.3M.3Y:30.5",
            "AUD.DBS.OIS.3M.4Y:30",
            "AUD.DBS.OIS.3M.5Y:29.5",
            "AUD.DBS.OIS.3M.6Y:28.75",
            "AUD.DBS.OIS.3M.7Y:28",
            "AUD.DBS.OIS.3M.8Y:27.75",
            "AUD.DBS.OIS.3M.9Y:27.5",
            "AUD.DBS.OIS.3M.10Y:27.25",
            "AUD.DBS.OIS.3M.12Y:27",
            "AUD.DBS.OIS.3M.15Y:27",
            "AUD.DBS.OIS.3M.20Y:26.5",
            "AUD.DBS.OIS.3M.25Y:26.5",
            "AUD.DBS.OIS.3M.30Y:26.5",
            "AUD.DBS.1M.3M.3M:13.875",
            "AUD.DBS.1M.3M.6M:15.5",
            "AUD.DBS.1M.3M.9M:15.875",
            "AUD.DBS.1M.3M.1Y:16.5",
            "AUD.DBS.1M.3M.2Y:16.25",
            "AUD.DBS.1M.3M.3Y:15.75",
            "AUD.DBS.1M.3M.4Y:15.125",
            "AUD.DBS.1M.3M.5Y:14.625",
            "AUD.DBS.1M.3M.6Y:13.875",
            "AUD.DBS.1M.3M.7Y:13.125",
            "AUD.DBS.1M.3M.8Y:12.875",
            "AUD.DBS.1M.3M.9Y:12.625",
            "AUD.DBS.1M.3M.10Y:12.375",
            "AUD.DBS.1M.3M.12Y:11.75",
            "AUD.DBS.1M.3M.15Y:11.25",
            "AUD.DBS.1M.3M.20Y:10.75",
            "AUD.DBS.1M.3M.25Y:10.75",
            "AUD.DBS.1M.3M.30Y:10.75",
            "AUD.DBS.3M.6M.6M:22.875",
            "AUD.DBS.3M.6M.1Y:22.875",
            "AUD.DBS.3M.6M.2Y:21.75",
            "AUD.DBS.3M.6M.3Y:20.375",
            "AUD.DBS.3M.6M.4Y:19.25",
            "AUD.DBS.3M.6M.5Y:18.125",
            "AUD.DBS.3M.6M.6Y:17.125",
            "AUD.DBS.3M.6M.7Y:16",
            "AUD.DBS.3M.6M.8Y:14.875",
            "AUD.DBS.3M.6M.9Y:13.875",
            "AUD.DBS.3M.6M.10Y:13.25",
            "AUD.DBS.3M.6M.12Y:12",
            "AUD.DBS.3M.6M.15Y:10.25",
            "AUD.DBS.3M.6M.20Y:9",
            "AUD.DBS.3M.6M.25Y:8.625",
            "AUD.DBS.3M.6M.30Y:7.75",
            "AUD.CPI@44469:119.7",
            "AUD.CPI@44561:121.3",
            "AUD.CPI@44651:121.8",
            "AUD.CPI@44742:122.3",
            "AUD.ZCIS.1Y:2.06",
            "AUD.ZCIS.2Y:2.099",
            "AUD.ZCIS.3Y:2.217",
            "AUD.ZCIS.4Y:2.48",
            "AUD.ZCIS.5Y:2.65",
            "AUD.ZCIS.6Y:2.816",
            "AUD.ZCIS.7Y:2.96",
            "AUD.ZCIS.8Y:3.08",
            "AUD.ZCIS.9Y:3.183",
            "AUD.ZCIS.10Y:3.27",
            "AUD.ZCIS.12Y:3.415",
            "AUD.ZCIS.15Y:3.555",
            "AUD.ZCIS.20Y:3.655",
            "AUD.ZCIS.25Y:3.704",
            "AUD.ZCIS.30Y:3.707",
            "AUD.ZCIS.40Y:3.651",
            "CAD.DEPO.OIS:0.4301",
            "CAD.OIS.1M:0.3226",
            "CAD.OIS.2M:0.3206",
            "CAD.OIS.3M:0.3176",
            "CAD.OIS.4M:0.3146",
            "CAD.OIS.5M:0.3126",
            "CAD.OIS.6M:0.3116",
            "CAD.OIS.7M:0.3106",
            "CAD.OIS.8M:0.3116",
            "CAD.OIS.9M:0.3126",
            "CAD.OIS.10M:0.3166",
            "CAD.OIS.11M:0.3206",
            "CAD.OIS.1Y:0.3246",
            "CAD.IRS.3M.2Y:0.8431",
            "CAD.IRS.3M.3Y:0.9646",
            "CAD.IRS.3M.4Y:1.0716",
            "CAD.IRS.3M.5Y:1.1736",
            "CAD.IRS.3M.6Y:1.2726",
            "CAD.IRS.3M.7Y:1.3711",
            "CAD.IRS.3M.8Y:1.4696",
            "CAD.IRS.3M.9Y:1.5696",
            "CAD.IRS.3M.10Y:1.6601",
            "CAD.IRS.3M.12Y:1.8216",
            "CAD.IRS.3M.15Y:1.9956",
            "CAD.IRS.3M.20Y:2.1061",
            "CAD.IRS.3M.25Y:2.0956",
            "CAD.IRS.3M.30Y:2.0586",
            "CHF.DEPO.OIS:-0.75",
            "CHF.DEPO.1M:-1.009",
            "CHF.DEPO.2M:-0.984",
            "CHF.DEPO.3M:-0.954",
            "CHF.DEPO.4M:-1.024",
            "CHF.DEPO.5M:-1.024",
            "CHF.DEPO.6M:-1.099",
            "CHF.FIX.1M:-0.7874",
            "CHF.FIX.2M:-0.7764",
            "CHF.FIX.3M:-0.7464",
            "CHF.FIX.6M:-0.675",
            "CHF.OIS.1M:-0.764",
            "CHF.OIS.2M:-0.789",
            "CHF.OIS.3M:-0.809",
            "CHF.OIS.4M:-0.824",
            "CHF.OIS.5M:-0.839",
            "CHF.OIS.6M:-0.854",
            "CHF.OIS.7M:-0.864",
            "CHF.OIS.8M:-0.874",
            "CHF.OIS.9M:-0.879",
            "CHF.OIS.10M:-0.884",
            "CHF.OIS.11M:-0.889",
            "CHF.OIS.1Y:-0.894",
            "CHF.OIS.2Y:-0.899",
            "CHF.OIS.3Y:-0.859",
            "CHF.OIS.4Y:-0.794",
            "CHF.OIS.5Y:-0.719",
            "CHF.OIS.6Y:-0.639",
            "CHF.OIS.7Y:-0.559",
            "CHF.OIS.8Y:-0.479",
            "CHF.OIS.9Y:-0.404",
            "CHF.OIS.10Y:-0.329",
            "CHF.OIS.12Y:-0.204",
            "CHF.OIS.15Y:-0.069",
            "CHF.OIS.20Y:0.056",
            "CHF.OIS.25Y:0.131",
            "CHF.OIS.30Y:0.181",
            "CHF.IRS.6M.1Y:-0.9315",
            "CHF.IRS.6M.2Y:-0.899",
            "CHF.IRS.6M.3Y:-0.819",
            "CHF.IRS.6M.4Y:-0.7165",
            "CHF.IRS.6M.5Y:-0.604",
            "CHF.IRS.6M.6Y:-0.4965",
            "CHF.IRS.6M.7Y:-0.3965",
            "CHF.IRS.6M.9Y:-0.219",
            "CHF.IRS.6M.10Y:-0.144",
            "CHF.IRS.6M.11Y:-0.079",
            "CHF.IRS.6M.12Y:-0.0215",
            "CHF.IRS.6M.15Y:0.096",
            "CHF.IRS.6M.20Y:0.196",
            "CHF.IRS.6M.25Y:0.2535",
            "CHF.IRS.6M.30Y:0.291",
            "CHF.DBS.1M.3M.1Y:5.5",
            "CHF.DBS.1M.3M.2Y:4.5",
            "CHF.DBS.1M.3M.3Y:4.25",
            "CHF.DBS.1M.3M.4Y:4.25",
            "CHF.DBS.1M.3M.5Y:4",
            "CHF.DBS.1M.3M.6Y:4",
            "CHF.DBS.1M.3M.7Y:3.75",
            "CHF.DBS.1M.3M.8Y:3.5",
            "CHF.DBS.1M.3M.9Y:3.5",
            "CHF.DBS.1M.3M.10Y:3.5",
            "CHF.DBS.1M.3M.12Y:3.25",
            "CHF.DBS.1M.3M.15Y:3.25",
            "CHF.DBS.1M.3M.20Y:3",
            "CHF.DBS.1M.3M.25Y:2.75",
            "CHF.DBS.1M.3M.30Y:2.75",
            "CHF.DBS.3M.6M.1Y:10.75",
            "CHF.DBS.3M.6M.2Y:12.25",
            "CHF.DBS.3M.6M.3Y:13.75",
            "CHF.DBS.3M.6M.4Y:15",
            "CHF.DBS.3M.6M.5Y:16.25",
            "CHF.DBS.3M.6M.6Y:16.75",
            "CHF.DBS.3M.6M.7Y:17",
            "CHF.DBS.3M.6M.8Y:17.25",
            "CHF.DBS.3M.6M.9Y:17",
            "CHF.DBS.3M.6M.10Y:16.75",
            "CHF.DBS.3M.6M.12Y:15.75",
            "CHF.DBS.3M.6M.15Y:13.25",
            "CHF.DBS.3M.6M.20Y:10.25",
            "CHF.DBS.3M.6M.25Y:8.75",
            "CHF.DBS.3M.6M.30Y:7.5",
            "EUR.DEPO.OIS:-0.489",
            "EUR.DEPO.1M:-0.521",
            "EUR.DEPO.2M:-0.491",
            "EUR.DEPO.3M:-0.496",
            "EUR.DEPO.4M:-0.461",
            "EUR.DEPO.5M:-0.451",
            "EUR.DEPO.6M:-0.416",
            "EUR.FIX.1M:-0.373",
            "EUR.FIX.3M:-0.313",
            "EUR.FIX.6M:-0.22",
            "EUR.FIX.1Y:-0.078",
            "EUR.OIS.1M:-0.491",
            "EUR.OIS.2M:-0.489",
            "EUR.OIS.3M:-0.49",
            "EUR.OIS.4M:-0.491",
            "EUR.OIS.5M:-0.492",
            "EUR.OIS.6M:-0.492",
            "EUR.OIS.7M:-0.492",
            "EUR.OIS.8M:-0.492",
            "EUR.OIS.9M:-0.493",
            "EUR.OIS.10M:-0.493",
            "EUR.OIS.11M:-0.494",
            "EUR.OIS.1Y:-0.494",
            "EUR.OIS.2Y:-0.489",
            "EUR.OIS.3Y:-0.452",
            "EUR.OIS.4Y:-0.388",
            "EUR.OIS.5Y:-0.3",
            "EUR.OIS.6Y:-0.19",
            "EUR.OIS.7Y:-0.068",
            "EUR.OIS.8Y:0.055",
            "EUR.OIS.9Y:0.173",
            "EUR.OIS.10Y:0.282",
            "EUR.OIS.11Y:0.379",
            "EUR.OIS.12Y:0.465",
            "EUR.OIS.15Y:0.661",
            "EUR.OIS.20Y:0.83",
            "EUR.OIS.25Y:0.891",
            "EUR.OIS.30Y:0.922",
            "EUR.IRS.3M.1Y:-0.342",
            "EUR.IRS.3M.2Y:-0.296",
            "EUR.IRS.3M.3Y:-0.233",
            "EUR.IRS.3M.4Y:-0.154",
            "EUR.IRS.3M.5Y:-0.056",
            "EUR.IRS.3M.6Y:0.056",
            "EUR.IRS.3M.7Y:0.176",
            "EUR.IRS.3M.8Y:0.296",
            "EUR.IRS.3M.9Y:0.408",
            "EUR.IRS.3M.10Y:0.51",
            "EUR.IRS.3M.11Y:0.6",
            "EUR.IRS.3M.12Y:0.679",
            "EUR.IRS.3M.13Y:0.747",
            "EUR.IRS.3M.14Y:0.806",
            "EUR.IRS.3M.15Y:0.855",
            "EUR.IRS.3M.16Y:0.896",
            "EUR.IRS.3M.17Y:0.929",
            "EUR.IRS.3M.18Y:0.956",
            "EUR.IRS.3M.19Y:0.977",
            "EUR.IRS.3M.20Y:0.994",
            "EUR.IRS.3M.21Y:1.007",
            "EUR.IRS.3M.22Y:1.017",
            "EUR.IRS.3M.23Y:1.024",
            "EUR.IRS.3M.24Y:1.03",
            "EUR.IRS.3M.25Y:1.034",
            "EUR.IRS.3M.26Y:1.038",
            "EUR.IRS.3M.27Y:1.041",
            "EUR.IRS.3M.28Y:1.044",
            "EUR.IRS.3M.29Y:1.046",
            "EUR.IRS.3M.30Y:1.048",
            "EUR.DBS.1M.3M.1Y:8.1",
            "EUR.DBS.1M.3M.2Y:9.6",
            "EUR.DBS.1M.3M.3Y:10.4",
            "EUR.DBS.1M.3M.4Y:10.9",
            "EUR.DBS.1M.3M.5Y:11.3",
            "EUR.DBS.1M.3M.6Y:11.4",
            "EUR.DBS.1M.3M.7Y:11.3",
            "EUR.DBS.1M.3M.8Y:11.1",
            "EUR.DBS.1M.3M.9Y:11",
            "EUR.DBS.1M.3M.10Y:10.8",
            "EUR.DBS.1M.3M.11Y:10.7",
            "EUR.DBS.1M.3M.12Y:10.6",
            "EUR.DBS.1M.3M.15Y:9.9",
            "EUR.DBS.1M.3M.20Y:8.7",
            "EUR.DBS.1M.3M.25Y:7.7",
            "EUR.DBS.1M.3M.30Y:6.9",
            "EUR.DBS.1M.3M.60Y:5",
            "EUR.DBS.3M.6M.1Y:9.6",
            "EUR.DBS.3M.6M.2Y:11.05",
            "EUR.DBS.3M.6M.3Y:12.15",
            "EUR.DBS.3M.6M.4Y:13.05",
            "EUR.DBS.3M.6M.5Y:13.6",
            "EUR.DBS.3M.6M.6Y:13.65",
            "EUR.DBS.3M.6M.7Y:13.45",
            "EUR.DBS.3M.6M.8Y:13.05",
            "EUR.DBS.3M.6M.9Y:12.5",
            "EUR.DBS.3M.6M.10Y:11.8",
            "EUR.DBS.3M.6M.11Y:11.1",
            "EUR.DBS.3M.6M.12Y:10.5",
            "EUR.DBS.3M.6M.15Y:8.9",
            "EUR.DBS.3M.6M.20Y:6.95",
            "EUR.DBS.3M.6M.25Y:5.75",
            "EUR.DBS.3M.6M.30Y:4.9",
            "EUR.DBS.3M.6M.60Y:2.7",
            "EUR.DBS.3M.12M.1Y:21.7",
            "EUR.DBS.3M.12M.2Y:23.5",
            "EUR.DBS.3M.12M.3Y:24.9",
            "EUR.DBS.3M.12M.4Y:26.1",
            "EUR.DBS.3M.12M.5Y:26.7",
            "EUR.DBS.3M.12M.6Y:26.8",
            "EUR.DBS.3M.12M.7Y:26.4",
            "EUR.DBS.3M.12M.8Y:25.8",
            "EUR.DBS.3M.12M.9Y:25",
            "EUR.DBS.3M.12M.10Y:23.9",
            "EUR.DBS.3M.12M.11Y:22.5",
            "EUR.DBS.3M.12M.12Y:21.3",
            "EUR.DBS.3M.12M.15Y:18.1",
            "EUR.DBS.3M.12M.20Y:14.6",
            "EUR.DBS.3M.12M.25Y:12.5",
            "EUR.DBS.3M.12M.30Y:11",
            "EUR.DBS.3M.12M.60Y:7.1",
            "GBP.DEPO.OIS:0.6661",
            "GBP.DEPO.1M:0.7893",
            "GBP.DEPO.2M:0.8293",
            "GBP.DEPO.3M:0.8843",
            "GBP.DEPO.4M:0.9293",
            "GBP.DEPO.5M:0.9793",
            "GBP.DEPO.6M:1.0493",
            "GBP.FIX.1M:0.2618",
            "GBP.FIX.2M:0.3271",
            "GBP.FIX.3M:0.3984",
            "GBP.FIX.6M:0.5573",
            "GBP.FIX.1Y:0.799",
            "GBP.OIS.1M:0.6623",
            "GBP.OIS.2M:0.6573",
            "GBP.OIS.3M:0.6563",
            "GBP.OIS.4M:0.6563",
            "GBP.OIS.5M:0.6553",
            "GBP.OIS.6M:0.6553",
            "GBP.OIS.7M:0.6563",
            "GBP.OIS.8M:0.6563",
            "GBP.OIS.9M:0.6593",
            "GBP.OIS.10M:0.6623",
            "GBP.OIS.11M:0.6663",
            "GBP.OIS.1Y:0.6713",
            "GBP.OIS.2Y:0.7523",
            "GBP.OIS.3Y:0.8663",
            "GBP.OIS.4Y:0.9813",
            "GBP.OIS.5Y:1.0933",
            "GBP.OIS.6Y:1.2033",
            "GBP.OIS.7Y:1.3043",
            "GBP.OIS.8Y:1.3983",
            "GBP.OIS.9Y:1.4823",
            "GBP.OIS.10Y:1.5583",
            "GBP.OIS.12Y:1.6783",
            "GBP.OIS.15Y:1.7983",
            "GBP.OIS.20Y:1.8783",
            "GBP.OIS.25Y:1.8873",
            "GBP.OIS.30Y:1.8813",
            "GBP.IRS.6M.2Y:1.1203",
            "GBP.IRS.6M.3Y:1.2263",
            "GBP.IRS.6M.4Y:1.3353",
            "GBP.IRS.6M.5Y:1.4383",
            "GBP.IRS.6M.6Y:1.5343",
            "GBP.IRS.6M.7Y:1.6233",
            "GBP.IRS.6M.8Y:1.7033",
            "GBP.IRS.6M.9Y:1.7753",
            "GBP.IRS.6M.10Y:1.8393",
            "GBP.IRS.6M.11Y:1.8933",
            "GBP.IRS.6M.12Y:1.9383",
            "GBP.IRS.6M.15Y:2.0313",
            "GBP.IRS.6M.20Y:2.0773",
            "GBP.IRS.6M.25Y:2.0643",
            "GBP.IRS.6M.30Y:2.0423",
            "GBP.DBS.1M.3M.1Y:13.1",
            "GBP.DBS.1M.3M.2Y:12.3",
            "GBP.DBS.1M.3M.3Y:11.3",
            "GBP.DBS.1M.3M.4Y:10.3",
            "GBP.DBS.1M.3M.5Y:9.5",
            "GBP.DBS.1M.3M.6Y:9",
            "GBP.DBS.1M.3M.7Y:8.7",
            "GBP.DBS.1M.3M.8Y:8.5",
            "GBP.DBS.1M.3M.9Y:8.2",
            "GBP.DBS.1M.3M.10Y:7.8",
            "GBP.DBS.1M.3M.12Y:7.1",
            "GBP.DBS.1M.3M.15Y:6.3",
            "GBP.DBS.1M.3M.20Y:5.4",
            "GBP.DBS.1M.3M.25Y:4.6",
            "GBP.DBS.1M.3M.30Y:3.9",
            "GBP.DBS.1M.3M.60Y:2.4",
            "GBP.DBS.3M.6M.1Y:14.3",
            "GBP.DBS.3M.6M.2Y:13.5",
            "GBP.DBS.3M.6M.3Y:12.6",
            "GBP.DBS.3M.6M.4Y:12",
            "GBP.DBS.3M.6M.5Y:11.5",
            "GBP.DBS.3M.6M.6Y:10.9",
            "GBP.DBS.3M.6M.7Y:10.4",
            "GBP.DBS.3M.6M.8Y:9.9",
            "GBP.DBS.3M.6M.9Y:9.4",
            "GBP.DBS.3M.6M.10Y:8.9",
            "GBP.DBS.3M.6M.12Y:8",
            "GBP.DBS.3M.6M.15Y:6.8",
            "GBP.DBS.3M.6M.20Y:5.1",
            "GBP.DBS.3M.6M.25Y:4.1",
            "GBP.DBS.3M.6M.30Y:3.4",
            "GBP.DBS.3M.6M.60Y:1.8",
            "GBP.DBS.3M.12M.1Y:36",
            "GBP.DBS.3M.12M.2Y:39.3",
            "GBP.DBS.3M.12M.3Y:33.3",
            "GBP.DBS.3M.12M.4Y:31.4",
            "GBP.DBS.3M.12M.5Y:30.3",
            "GBP.DBS.3M.12M.6Y:29.5",
            "GBP.DBS.3M.12M.7Y:28.6",
            "GBP.DBS.3M.12M.8Y:27.6",
            "GBP.DBS.3M.12M.9Y:26.8",
            "GBP.DBS.3M.12M.10Y:26",
            "GBP.DBS.3M.12M.12Y:23.5",
            "GBP.DBS.3M.12M.15Y:20.4",
            "GBP.DBS.3M.12M.20Y:16.9",
            "GBP.DBS.3M.12M.25Y:14.3",
            "GBP.DBS.3M.12M.30Y:12.6",
            "GBP.DBS.3M.12M.60Y:8.5",
            "JPY.DEPO.1M:-0.0235",
            "JPY.DEPO.2M:-0.0097",
            "JPY.DEPO.3M:0.0015",
            "JPY.DEPO.6M:0.0453",
            "JPY.DEPO.OIS:-0.1075",
            "JPY.OIS.1M:-0.106",
            "JPY.OIS.2M:-0.106",
            "JPY.OIS.3M:-0.1085",
            "JPY.OIS.4M:-0.111",
            "JPY.OIS.5M:-0.111",
            "JPY.OIS.6M:-0.1135",
            "JPY.OIS.9M:-0.116",
            "JPY.OIS.1Y:-0.1185",
            "JPY.OIS.2Y:-0.1235",
            "JPY.OIS.3Y:-0.1235",
            "JPY.OIS.4Y:-0.126",
            "JPY.OIS.5Y:-0.1235",
            "JPY.OIS.6Y:-0.1185",
            "JPY.OIS.7Y:-0.1085",
            "JPY.OIS.8Y:-0.0985",
            "JPY.OIS.9Y:-0.086",
            "JPY.OIS.10Y:-0.0685",
            "JPY.OIS.12Y:-0.0085",
            "JPY.OIS.15Y:0.109",
            "JPY.OIS.20Y:0.274",
            "JPY.OIS.25Y:0.369",
            "JPY.OIS.30Y:0.424",
            "JPY.DBS.1M.3M.3M:0.75",
            "JPY.DBS.1M.3M.6M:2.75",
            "JPY.DBS.1M.3M.9M:3.625",
            "JPY.DBS.1M.3M.2Y:3.875",
            "JPY.DBS.1M.3M.3Y:3.5",
            "JPY.DBS.1M.3M.4Y:3.125",
            "JPY.DBS.1M.3M.5Y:2.75",
            "JPY.DBS.1M.3M.6Y:2.5",
            "JPY.DBS.1M.3M.7Y:2.375",
            "JPY.DBS.1M.3M.8Y:2.25",
            "JPY.DBS.1M.3M.9Y:2.25",
            "JPY.DBS.1M.3M.10Y:2.125",
            "JPY.DBS.1M.3M.12Y:2",
            "JPY.DBS.1M.3M.15Y:2",
            "JPY.DBS.1M.3M.20Y:2",
            "JPY.DBS.1M.3M.25Y:1.875",
            "JPY.DBS.1M.3M.30Y:1.75",
            "JPY.DBS.3M.6M.6M:4.625",
            "JPY.DBS.3M.6M.1Y:4.25",
            "JPY.DBS.3M.6M.1Y:4.25",
            "JPY.DBS.3M.6M.18M:4",
            "JPY.DBS.3M.6M.2Y:3.75",
            "JPY.DBS.3M.6M.2Y:3.75",
            "JPY.DBS.3M.6M.3Y:3.625",
            "JPY.DBS.3M.6M.3Y:3.625",
            "JPY.DBS.3M.6M.4Y:3.75",
            "JPY.DBS.3M.6M.4Y:3.75",
            "JPY.DBS.3M.6M.5Y:4",
            "JPY.DBS.3M.6M.5Y:4",
            "JPY.DBS.3M.6M.6Y:4.375",
            "JPY.DBS.3M.6M.6Y:4.375",
            "JPY.DBS.3M.6M.7Y:4.875",
            "JPY.DBS.3M.6M.7Y:4.875",
            "JPY.DBS.3M.6M.8Y:5.5",
            "JPY.DBS.3M.6M.8Y:5.5",
            "JPY.DBS.3M.6M.9Y:6.125",
            "JPY.DBS.3M.6M.9Y:6.125",
            "JPY.DBS.3M.6M.10Y:6.75",
            "JPY.DBS.3M.6M.10Y:6.75",
            "JPY.DBS.3M.6M.11Y:7.125",
            "JPY.DBS.3M.6M.11Y:7.125",
            "JPY.DBS.3M.6M.12Y:7.5",
            "JPY.DBS.3M.6M.12Y:7.5",
            "JPY.DBS.3M.6M.15Y:8",
            "JPY.DBS.3M.6M.15Y:8",
            "JPY.DBS.3M.6M.20Y:8.625",
            "JPY.DBS.3M.6M.20Y:8.625",
            "JPY.DBS.3M.6M.25Y:9.25",
            "JPY.DBS.3M.6M.25Y:9.25",
            "JPY.DBS.3M.6M.30Y:9.75",
            "JPY.DBS.3M.6M.30Y:9.75",
            "JPY.DBS.3M.6M.35Y:9.75",
            "JPY.DBS.3M.6M.35Y:9.75",
            "JPY.DBS.3M.6M.40Y:9.75",
            "JPY.DBS.3M.6M.40Y:9.75",
            "JPY.DBS.6M.6M.1Y:8.75",
            "JPY.DBS.6M.6M.2Y:7.875",
            "JPY.DBS.6M.6M.3Y:7.25",
            "JPY.DBS.6M.6M.4Y:7",
            "JPY.DBS.6M.6M.5Y:6.75",
            "JPY.DBS.6M.6M.6Y:6.5",
            "JPY.DBS.6M.6M.7Y:6.375",
            "JPY.DBS.6M.6M.8Y:6.25",
            "JPY.DBS.6M.6M.9Y:6.125",
            "JPY.DBS.6M.6M.10Y:6",
            "JPY.DBS.6M.6M.11Y:5.875",
            "JPY.DBS.6M.6M.12Y:5.75",
            "JPY.DBS.6M.6M.15Y:5.625",
            "JPY.DBS.6M.6M.20Y:5.5",
            "JPY.DBS.6M.6M.25Y:5.5",
            "JPY.DBS.6M.6M.30Y:5.5",
            "JPY.DBS.6M.6M.35Y:5.5",
            "JPY.DBS.6M.6M.40Y:5.5",
            "SGD.FIX.1M:0.4401",
            "SGD.FIX.3M:0.7858",
            "SGD.FIX.6M:1.1962",
            "USD.DEPO.OIS:1.5405",
            "USD.DEPO.1M:1.7305",
            "USD.DEPO.2M:1.8605",
            "USD.DEPO.3M:2.0705",
            "USD.DEPO.4M:2.1905",
            "USD.DEPO.5M:2.3205",
            "USD.DEPO.6M:2.4205",
            "USD.FIX.1M:0.592",
            "USD.FIX.2M:0.7258",
            "USD.FIX.3M:0.9301",
            "USD.FIX.6M:1.2799",
            "USD.FIX.1Y:1.6296",
            "USD.OIS.1M:1.6415",
            "USD.OIS.2M:1.7045",
            "USD.OIS.3M:1.7295",
            "USD.OIS.4M:1.7505",
            "USD.OIS.5M:1.7695",
            "USD.OIS.6M:1.7865",
            "USD.OIS.7M:1.8065",
            "USD.OIS.8M:1.8295",
            "USD.OIS.9M:1.8485",
            "USD.OIS.10M:1.8675",
            "USD.OIS.11M:1.8885",
            "USD.OIS.1Y:1.9065",
            "USD.OIS.2Y:2.1345",
            "USD.OIS.3Y:2.3415",
            "USD.OIS.4Y:2.5125",
            "USD.OIS.5Y:2.6465",
            "USD.OIS.6Y:2.7505",
            "USD.OIS.7Y:2.8305",
            "USD.OIS.8Y:2.8965",
            "USD.OIS.9Y:2.9525",
            "USD.OIS.10Y:2.9995",
            "USD.OIS.12Y:3.0745",
            "USD.OIS.15Y:3.1455",
            "USD.OIS.20Y:3.2085",
            "USD.OIS.25Y:3.2275",
            "USD.OIS.30Y:3.2295",
            "USD.IRS.3M.2Y:2.467",
            "USD.IRS.3M.3Y:2.685",
            "USD.IRS.3M.4Y:2.856",
            "USD.IRS.3M.5Y:2.987",
            "USD.IRS.3M.6Y:3.094",
            "USD.IRS.3M.7Y:3.1735",
            "USD.IRS.3M.8Y:3.249",
            "USD.IRS.3M.9Y:3.3025",
            "USD.IRS.3M.10Y:3.3455",
            "USD.IRS.3M.12Y:3.433",
            "USD.IRS.3M.15Y:3.508",
            "USD.IRS.3M.20Y:3.576",
            "USD.IRS.3M.25Y:3.6065",
            "USD.IRS.3M.30Y:3.6015",
            "USD.DBS.1M.3M.1Y:21",
            "USD.DBS.1M.3M.2Y:18.9",
            "USD.DBS.1M.3M.3Y:17.3",
            "USD.DBS.1M.3M.4Y:15.7",
            "USD.DBS.1M.3M.5Y:14.4",
            "USD.DBS.1M.3M.6Y:13.2",
            "USD.DBS.1M.3M.7Y:12.5",
            "USD.DBS.1M.3M.8Y:11.7",
            "USD.DBS.1M.3M.9Y:11.1",
            "USD.DBS.1M.3M.10Y:10.7",
            "USD.DBS.1M.3M.12Y:9.7",
            "USD.DBS.1M.3M.15Y:9.1",
            "USD.DBS.1M.3M.20Y:9.6",
            "USD.DBS.1M.3M.25Y:10.6",
            "USD.DBS.1M.3M.30Y:11.5",
            "USD.DBS.3M.6M.6M:30.1",
            "USD.DBS.3M.6M.1Y:27.7",
            "USD.DBS.3M.6M.2Y:25.2",
            "USD.DBS.3M.6M.3Y:22.9",
            "USD.DBS.3M.6M.4Y:21.4",
            "USD.DBS.3M.6M.5Y:20.2",
            "USD.DBS.3M.6M.6Y:19.3",
            "USD.DBS.3M.6M.7Y:18.7",
            "USD.DBS.3M.6M.8Y:18.3",
            "USD.DBS.3M.6M.9Y:18",
            "USD.DBS.3M.6M.10Y:17.8",
            "USD.DBS.3M.6M.12Y:17.7",
            "USD.DBS.3M.6M.15Y:17.3",
            "USD.DBS.3M.6M.20Y:16.5",
            "USD.DBS.3M.6M.25Y:16",
            "USD.DBS.3M.6M.30Y:15.8",
            "JPY.IRS.6M.1Y:0.049",
            "JPY.IRS.6M.2Y:0.0553",
            "JPY.IRS.6M.3Y:0.0615",
            "JPY.IRS.6M.4Y:0.074",
            "JPY.IRS.6M.5Y:0.089",
            "JPY.IRS.6M.6Y:0.1065",
            "JPY.IRS.6M.7Y:0.1278",
            "JPY.IRS.6M.8Y:0.154",
            "JPY.IRS.6M.9Y:0.1803",
            "JPY.IRS.6M.10Y:0.2115",
            "JPY.IRS.6M.12Y:0.284",
            "JPY.IRS.6M.15Y:0.4178",
            "JPY.IRS.6M.20Y:0.6015",
            "JPY.IRS.6M.25Y:0.714",
            "JPY.IRS.6M.30Y:0.7865"]
            .map((quote: string) => { return parse(quote) });
    }

const Quotes: Quote[] = getQuotes();

export default function Sample() {
    return <span onClick={() => store.MarketData.Current.Quotes.next(Quotes.filter(quote=>quote.Currency === store.MarketData.Currency.getValue()))}>Sample</span>;
}
    

