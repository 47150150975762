import React from 'react';
import { Link } from "react-router-dom";
import './TopBar.css';

export default (props: any) =>
    <div className="TopBar">
        <table>
            <tbody>
                <tr>
                    {props.routes.map((route: any) => <td key={route.path()}>
                        <Link to={route.path()}>
                            <u>{route.label.substring(0, 1)}</u>{route.label.substring(1, route.label.length)}
                        </Link>
                    </td>
                    )}
                </tr>
            </tbody>
        </table>
    </div>
