import React from 'react';
import Link from '../buttons/Link';
import './NavigationBar.css';

export default (props: any) =>
    <div className="NavigationBar">
        {props.routes.slice(0, props.routes.length - 1).map((route: any) => 
            <div key={`${route.path()}:start`}><Link key={route.path()} {...route} /><span> - </span></div>
        )}
        <Link key={props.routes[props.routes.length - 1].path()}  {...props.routes[props.routes.length - 1]} />
    </div>
