import React from 'react';

interface IProps {
    index: number,
    deleteTrade: any
}

export default function Delete(props: IProps) {
    return (
        <div className="Button" key="Delete" onClick={() => props.deleteTrade(props.index)}>Delete</div>
    );
}