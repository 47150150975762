import Dates from "./Dates";

declare global {
    interface Array<T>{
        IsEmpty(this: T[]): boolean;
    }

    interface Date{
        Parse(this: Date, str: string): Date;
        ToString(this: Date): string;
        ToAODate(this: Date): number;
        ToUtc(this: Date): number;
    }

    interface Number{
        FromAODate(this: number): Date;
    }
}

// eslint-disable-next-line
Object.defineProperty(Array.prototype, "IsEmpty", {
    value: function IsEmpty(this: any[]): boolean {
        return this.length === 0;
    },
    writable: true,
    configurable: true
});

// eslint-disable-next-line
Object.defineProperty(Date.prototype, "Parse", {
    value: function Parse(this: Date, str: string): Date {
        return Dates.Parse(str);
    },
    writable: true,
    configurable: true
});

// eslint-disable-next-line
Object.defineProperty(Date.prototype, "ToString", {
    value: function ToString(this: Date): string {
        return Dates.ToString(this);
    },
    writable: true,
    configurable: true
});

// eslint-disable-next-line
Object.defineProperty(Date.prototype, "ToAODate", {
    value: function ToAODate(this: Date): number {
        return Dates.ToAODate(this);
    },
    writable: true,
    configurable: true
});

// eslint-disable-next-line
Object.defineProperty(Number.prototype, "FromAODate", {
    value: function FromAODate(this: number): Date {
        return Dates.FromAODate(this);
    },
    writable: true,
    configurable: true
});

// eslint-disable-next-line
Object.defineProperty(Date.prototype, "ToUtc", {
    value: function ToUtc(this: Date): Date {
        return Dates.ToUtc(this);
    },
    writable: true,
    configurable: true
});

export { };

