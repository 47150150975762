import React, { useEffect, useState } from 'react';
import QuoteTable from '../tables/QuoteTable';
import store from '../../states/Store';
import Service from '../../classes/Service';
import Quote from '../../classes/Quote';
import Factory from '../../classes/Factory';

const parse = Quote.deserialize;
const headers = ['Type', 'Term', 'Value'];
const properties = ['Type', 'Maturity']

export default function SyntheticPage() {
  const tenors = ['OIS', '1M', '3M', '6M'];
  const [synthetics, setSynthetics] = useState([] as Quote[][])

  const service = async () => {
    const quotes = store.MarketData.Current.Quotes
      .getValue()
      .filter((quote) => quote && quote.Type !== '' && quote.Value !== '');
    if (Factory.IsNullOrEmpty(quotes)) return;
    const values: string[][] = await Service.post('market', 'CreateSyntheticInstruments', JSON.stringify(getData(quotes)));
    const synthetics: Quote[][] = values.map(array => array.map(str => parse(str)));
    store.MarketData.Current.SyntheticQuotes.next(synthetics);
  }

  const filter = (
    quotes: Quote[],
    type: string,
    tenor: string | null = null,
    frequency: string | null = null) =>
    quotes
      .filter((quote) =>
        quote.Type === type
        && (!tenor || quote.Tenor === tenor)
        && (!frequency || quote.FixedFrequency === frequency))
      .map((quote) => Quote.serialize(quote))

  const getData = (quotes: Quote[]): any => {
    return {
      deposits: filter(quotes, 'DEPO'),
      swapsOIS: filter(quotes, 'OIS'),
      swaps1M: filter(quotes, 'IRS', '1M'),
      swaps3M: filter(quotes, 'IRS', '3M'),
      swaps6M: filter(quotes, 'IRS', '6M'),
      basis0s3s: filter(quotes, 'DBS', 'OIS', '3M'),
      basis1s3s: filter(quotes, 'DBS', '1M', '3M'),
      basis3s6s: filter(quotes, 'DBS', '3M', '6M'),
    }
  }

  useEffect(() => {
    store.MarketData.Current.SyntheticQuotes.subscribe((synthetics) => setSynthetics(synthetics));
    service();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {tenors.map((tenor: string, index: number) => {
        return !synthetics[index] ? '' : (<QuoteTable
          key={tenor}
          legend={`Curve  ${tenor}`}
          headers={headers}
          properties={properties}
          Quotes={synthetics[index]} ></QuoteTable>);
      })}
    </div>);
}
