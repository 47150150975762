import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import './MainFrame.css';

const getHeight = (): number => {
  const documentElement = document.documentElement;
  const body = document.getElementsByTagName('body')[0];
  body.style.height = `${window.innerHeight}px`;
  return (window.innerHeight || documentElement.clientHeight) - 110;
}

export default function MainFrame(props: any) {
  const [height, setHeight] = useState(getHeight());
  const resize = () => setHeight(getHeight());

  useEffect(() => {
    window.addEventListener("resize", resize);

    return function cleanup() {
      window.removeEventListener("resize", resize);
    }
  })

  return (
    <div className="MainFrame" style={{ height: `${height}px` }}>
      {props.routes.map((route: {
        path: any;
        exact: any;
        main: any;
      },
        index: any) => (
        <Route
          key={index}
          path={route.path()}
          exact={route.exact}
          component={route.main}
        />
      ))}
    </div>
  );
}