import React from 'react';

export default () =>
    <div>
        <h1>Privacy Statement</h1>
        <p>We may collect information, which may include the following:</p>
        <ul>
            <li>Your IP address</li>
            <li>The date and the time</li>
            <li>Any of the following personal details you provide voluntarily in the Contact form:</li>
            <ul>
                <li>First Name</li>
                <li>Last Name</li>
                <li>Email</li>
                <li>Company</li>
            </ul>
            <li>Requests and Data sent to the server (including fields completed in forms)</li>
            <li>Information regarding your browser</li>
        </ul>
        <p>We use cookies when you visit our site mainly to retain session information.</p>
        <p>Internet activities are monitored for security, maintenance and statistics reasons.</p>
    </div>
