import React, { useState } from 'react';

interface IProps {
  value: string[],
  callBack: any
};

export default function InputArray(props: IProps) {
  const [value, setValue] = useState(props.value.join(' '));
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value);
  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => props.callBack(event.target.value.split(' '));

  return (
    <input
      type="text"
      value={value}
      onChange={onChange}
      onBlur={onBlur} />
  );
}

