import React from 'react';

export default () =>
    <div className="Downloads">
        <form>
            <table>
                <tbody>
                    <tr>
                        <td><a href="/files/ExcelPricer64.msi" download>ExcelPricer - Windows installer (64 bit)</a></td>
                    </tr>
                    <tr>
                        <td><a href="/files/ExcelPricer.msi" download>ExcelPricer - Windows installer (32 bit)</a></td>
                    </tr>
                    <tr>
                        <td><a href="/files/ExcelPricer64.zip" download>ExcelPricer - Zip file (64 bit)</a></td>
                    </tr>
                    <tr>
                        <td><a href="/files/ExcelPricer.zip" download>ExcelPricer - Zip file (32 bit)</a></td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
