import React from 'react';
import Dropdown from '../blocks/Dropdown';
import Strings from '../../classes/Strings';

interface IProperty {
    name: string,
    values: any[],
    value: any;
}

interface IProps {
    index: number,
    properties: IProperty[],
    callback: any
}

export default function CreateLeg(props: IProps) {
    return (
        <div className="CreateLeg">
            <table>
                <tbody>
                    {props.properties.map(property => {
                        const name = property.name;
                        const values = property.values;
                        const value = property.value;
                        return (
                            <tr key={`SwapLeg_${props.index}_${name}`}>
                                <td>{Strings.toTitleCase(name)}</td>
                                <td>
                                    {values.length === 0 ? '' :
                                        <Dropdown
                                            values={values}
                                            value={value}
                                            callback={(value: any) =>
                                                props.callback(props.index, name, value)} />}
                                </td>
                            </tr>);
                    })}
                </tbody>
            </table>
        </div>
    );
}
