import React from 'react';
import Trade from '../../classes/Trade';
import Price from '../buttons/Price';
import Delete from '../buttons/Delete';
import Dates from '../../classes/Dates';
import Numbers from '../../classes/Numbers';
import "../../css/Button.css"
import "./TradeRow.css"

interface IProps {
  index: number,
  trade: Trade,
  updateTrade: any,
  deleteTrade: any
}

export default function TradeRow(props: IProps) {
  const { trade, index, updateTrade, deleteTrade } = props;
  const acronyms = [["Floating", "Float"], ["Frequency", "Freq"]];
  const getParameters = (trade: Trade) => Object
    .keys(trade.Description)
    .filter(key => !["type", "currency", "valuationdate", "dateformat", "first", "second"].some(function (v) { return key.toLowerCase().indexOf(v) >= 0; }));

  const getResults = (trade: Trade) => Object
    .keys(trade.Valuation)
    .filter(key => !["dateFormat"].includes(key));

  const substitute = (str: string) => {
    if (!str || str === "") return "";
    acronyms.forEach(mapping =>
      str = str.replace(mapping[0], mapping[1])
    );
    return str;
  }

  const toTitleCase = (str: string) => str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1));

  const formatValue = (label: String, value: any) => {
    if (typeof (value) === 'boolean')
      return toTitleCase(value.toString());

    if (label.indexOf('Date') !== -1 && Numbers.isNumber(value) && value > 40000)
      return Dates.ToString(Dates.FromAODate(value));

    const number = Number(value);
    if (isNaN(number))
      return value;

    if (label.indexOf('Rate') !== -1)
      return number.toFixed(3);

    return number > 1000
      ? Numbers.toNotional(number)
      : number.toFixed(2);
  }

  const createCell = (label: string, value: any) =>
    value === '' ? '' : (
      <div key={label}>
        <span>{toTitleCase(label)}</span>
        <span>{formatValue(label, value)}</span>
      </div>
    );

  return (
    <li className="TradeRow">
      <div>
        <div>{index}</div>
        <div>{Trade.getType(trade)}</div>
        {getParameters(trade).map((key) => {
          return createCell(substitute(key), trade.Description[key]);
        })}
        {getResults(trade).map((key) => {
          return createCell(substitute(key), trade.Valuation[key]);
        })}
        <div></div>
        <div>
          <Price index={index} trade={trade} updateTrade={updateTrade} />
          <Delete index={index} deleteTrade={deleteTrade} />
        </div>
      </div>
    </li>
  );
}
