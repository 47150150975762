import React from 'react';
import store from "../../states/Store"
import Terms from '../pages/Terms';
import "./popup.css"
import "./popup-buttons.css"
import "./Disclaimer.css"

export default function Disclaimer() {
    const handleClickEvent = () => {
        store.UI.Disclaimer = false;
        store.UI.save();
    }

    return store.UI.Disclaimer ? null : (
        <div className="popup">
            <div className="Disclaimer">
                <Terms />
                <tbody className="popup-buttons">
                    <table>
                        <tr > 
                            <td onClick={handleClickEvent}>Accept</td>
                            <td onClick={() => { window.location.assign('http://google.com'); }}>Decline</td>
                        </tr>
                    </table>
                </tbody>
            </div>
        </div>
    );
}
