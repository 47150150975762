export default class Events {
    private static initialKeyDown: any = {};
    private static currentKeyDown: any = {};

    public static registerKeyDown = (key: string, action: any) => {
        Events.currentKeyDown[key] = action;
    }

    public static unregisterKeyDown = (key: string) => {
        delete Events.currentKeyDown[key];
    }

    public static onKeyDown = (e: any) => {
        if((e.key !== 'Enter' && e.key !== 'Escape' && !e.ctrlKey) || e.key === 'Control') return;
            
        var action = Events.currentKeyDown[e.key];
        if (action === undefined)
            action = Events.initialKeyDown[e.key];
        if (action !== undefined)
            action();
    }
}
